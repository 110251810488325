/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap"); */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  background: var(--background-color-1);
  height: 100;
}

img {
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  width: 7px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; 
  border-radius: 10px; */
  background: var(--background-color-5);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--background-color-5);
  border-radius: 50px;
  /* border-bottom-right-radius: 50px; */
  background: rgba(94, 94, 94, 0.459);

}

/* Typography css begin */

a {
  color: inherit !important;
  text-decoration: none;
}

/* ============ COMMON-CSS-START ============ */

/*----------------genealogy-scroll----------*/

.genealogy-scroll::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.genealogy-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #e4e4e4;
}

.genealogy-scroll::-webkit-scrollbar-thumb {
  background: #212121;
  border-radius: 10px;
  transition: 0.5s;
}

.genealogy-scroll::-webkit-scrollbar-thumb:hover {
  background: #d5b14c;
  transition: 0.5s;
}


/*----------------genealogy-tree----------*/
.genealogy-body {
  white-space: nowrap;
  text-align: center;
}

.genealogy-tree {
  display: inline-block;
}

.genealogy-tree ul {
  padding-top: 20px;
  position: relative;
  padding-left: 0px;
  display: flex;
  justify-content: center;
}

.genealogy-tree li {
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 5px;
}

.genealogy-tree li::before,
.genealogy-tree li::after {
  content: '';
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 2px solid #ccc;
  width: 50%;
  height: 18px;
}

.genealogy-tree li::after {
  right: auto;
  left: 50%;
  border-left: 2px solid #ccc;
}

.genealogy-tree li:only-child::after,
.genealogy-tree li:only-child::before {
  display: none;
}

.genealogy-tree li:only-child {
  padding-top: 0;
}

.genealogy-tree li:first-child::before,
.genealogy-tree li:last-child::after {
  border: 0 none;
}

.genealogy-tree li:last-child::before {
  border-right: 2px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}

.genealogy-tree li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

.genealogy-tree ul ul::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 2px solid #ccc;
  width: 0;
  height: 20px;
}

.genealogy-tree li a {
  text-decoration: none;
  color: #666;
  font-family: arial, verdana, tahoma;
  font-size: 11px;
  display: inline-block;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

.genealogy-tree li a:hover+ul li::after,
.genealogy-tree li a:hover+ul li::before,
.genealogy-tree li a:hover+ul::before,
.genealogy-tree li a:hover+ul ul::before {
  border-color: #fbba00;
}

/*--------------memeber-card-design----------*/
.member-view-box {
  padding: 0px 20px;
  text-align: center;
  border-radius: 4px;
  position: relative;
}

.member-image {

  position: relative;
  background-color: var(--background-color-4);
  color: var(--color-1) !important;
  padding: 10px 20px;
  font-weight: bold;
  border-radius: 20px;
  font-size: 14px;
}


.chart {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 200px;
  margin: 0 auto;
  background-image: linear-gradient(to top,
      rgba(78, 78, 78, 0.1) 2%,
      rgba(0, 0, 0, 0) 2%);
  background-size: 100% 50px;
  background-position: left top;
  font-size: 9px;
}

.chart li {
  position: relative;
  display: table-cell;
  vertical-align: bottom;
  height: 200px;
}

.chart li:hover {
  background-color: #ffffff2d;
}

.chart span {
  margin: 0 0.2em;
  display: block;
  background: var(--background-color-2);
  animation: draw 1s ease-in-out;
}

#datatable-example_info {
  padding-top: 30px;
  padding-bottom: 30px;
}

#datatable-example_paginate {
  padding-top: 30px;
  padding-bottom: 30px;
}

#datatable-example_length {
  display: none;
}

#datatable-example_filter {
  margin-bottom: 20px;
}

#datatable-example {
  background-color: var(--background-color-4);
  border-radius: 14px;
  font-size: 14px;
}

#datatable-example thead th {
  opacity: 0.6;
  font-size: 12px;
}

#datatable-example th {
  padding: 20px 10px;
}

#datatable-example td {
  padding: 20px 10px;
}

.xcore-total-grid-1 {
  background-color: #ffc853;
  border-radius: 50%;
  width: 70%;
  margin: auto;
}

.xcore-total-grid-2 {
  background: #fff583;
  border-radius: 50%;
  width: 26%;
  margin: auto;
  margin-bottom: -76px;
  z-index: 99;
  position: relative;
}

.xcore-total-grid-overrall {
  margin-top: 40px;
}

.xcore-card-1 {
  width: 185px;
}

.dashboard-user-xcore-all-level-programs {
  width: 0.96rem;
  height: 0.96rem;
  border-radius: 50%;
  background-color: #c1c1c1d4 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-user-xcore-all-level-2-programs {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background-color: #c1c1c1d4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-user-xcore-programs-active {
  background-color: #fff !important;
}

.dashboard-user-xcore-position-left-1 {
  position: relative;
  left: -8px;
}

.dashboard-user-xcore-position-right-1 {
  position: relative;
  left: 8px;
}

/* .grid-dashboard-xcore-inner-l1-container {
  background-size: 100% 100%;
  display: grid;
  grid-template-columns: repeat(1, auto);
  background-repeat: no-repeat;
  justify-content: center;
  position: relative;
  top: -8px;
} */
.grid-dashboard-xcore-inner-l2-container {
  background-size: 100% 100%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  background-repeat: no-repeat;
  justify-content: space-between;
}

.grid-dashboard-xcore-l1-container {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #ffc853;
  border-radius: 50%;
  display: grid;
  grid-template-columns: auto auto;
  margin: auto;
  gap: 4px;
  font-size: 10px;
  margin-top: 20px;
  color: #000;
}

.grid-dashboard-xcore-l2-container {
  background: #fff583;
  border-radius: 50%;
  width: 40px;
  margin-bottom: -86px !important;
  z-index: 99;
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  margin: auto;
  font-size: 10px;
  color: #000;
}

.grid-dashboard-xcore-l3-container {
  background-size: 100% 100%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  background-repeat: no-repeat;
  justify-content: space-between;
  position: relative;
  top: -14px;
  width: 100%;
  margin: auto;
}

.grid-dashboard-xcore-l4-container {
  background-size: 100% 100%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  background-repeat: no-repeat;
  justify-content: space-between;
  position: relative;
  top: -2px;
  width: 100%;
  margin: auto;
}

.grid-dashboard-xcore-l5-container {
  background-size: 100% 100%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  background-repeat: no-repeat;
  justify-content: space-between;
  position: relative;
  top: 4px;
  width: 50%;
  margin: auto;
}

.dashboard-user-x3-l1-programs {
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
  background-color: #5252522f;
}

.grid-dashboard-x3-l1-container {
  background-size: 100% 100%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 10px;
  background-repeat: no-repeat;
  justify-content: space-around;
}

.dashboard-user-x3-l2-programs {
  width: 1.175rem;
  height: 1.175rem;
  border-radius: 50%;
  background-color: #5252522f;
}

.grid-dashboard-x3-l2-container {
  background-size: 100% 100%;
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 8px;
  background-repeat: no-repeat;
  justify-content: space-around;
}

.dashboard-user-x3-l3-programs {
  width: 0.675rem;
  height: 0.675rem;
  border-radius: 50%;
  background-color: #5252522f;
}

.grid-dashboard-x3-l3-container {
  background-size: 100% 100%;
  display: grid;
  grid-template-columns: repeat(8, auto);
  gap: 6px;
  background-repeat: no-repeat;
  justify-content: space-around;
}

.dashboard-user-x3-programs-active {
  background-color: #fff !important;
}

.dashboard-user-gold-l1-programs {
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
  background-color: #5252522f;
}

.grid-dashboard-gold-l1-container {
  background-size: 100% 100%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 10px;
  background-repeat: no-repeat;
  justify-content: space-around;
}

.dashboard-user-gold-l2-programs {
  width: 1.175rem;
  height: 1.175rem;
  border-radius: 50%;
  background-color: #5252522f;
}

.grid-dashboard-gold-l2-container {
  background-size: 100% 100%;
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 8px;
  background-repeat: no-repeat;
  justify-content: space-around;
}

.dashboard-user-gold-l3-programs {
  width: 0.675rem;
  height: 0.675rem;
  border-radius: 50%;
  background-color: #5252522f;
}

.grid-dashboard-gold-l3-container {
  background-size: 100% 100%;
  display: grid;
  grid-template-columns: repeat(8, auto);
  gap: 6px;
  background-repeat: no-repeat;
  justify-content: space-around;
}

.dashboard-user-gold-l4-programs {
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background-color: #5252522f;
}

.grid-dashboard-gold-l4-container {
  background-size: 100% 100%;
  display: grid;
  grid-template-columns: repeat(16, auto);
  gap: 2px;
  background-repeat: no-repeat;
  justify-content: space-around;
}

.dashboard-user-gold-programs-active {
  background-color: #fff !important;
}

.xcore-total-grid-2 .grid-dashboard-xcore-inner-l1-container .dashboard-user-xcore-all-level-2-programs {
  margin: auto;
  position: relative;
  top: -8px;
}

/* ============ COMMON-CSS-END ============ */

/* ============ DESKTOP-VIEW ============ */

@media all and (min-width: 992px) {
  .xcore-inner-total-grid-1 .grid-dashboard-xcore-inner-l1-container {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: #ffc853;
    border-radius: 50%;
    width: 220px;
    display: grid;
    grid-template-columns: auto auto;
    margin: auto;
    gap: 10px;
    font-size: 12px;
  }

  .grid-dashboard-xcore-inner-inner-l2-container {
    background: #fff583;
    border-radius: 50%;
    width: 90px;
    margin-bottom: -140px !important;
    z-index: 99;
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    margin: auto;
    font-size: 12px;
  }

  .link-page-select {
    border: none;
    outline: none;
    background-color: transparent;
    padding: 4px 10px;
    color: #FFF;
    font-size: 14px;
  }

  .link-page-select option {
    color: #000;
  }

  .links-card-2-inner-1 {
    background-color: var(--background-color-5);
    border-radius: 20px;
    padding: 6px 10px;
  }

  .links-card-2 {
    padding: 18px 18px;
    background-color: var(--background-color-4);
    border-radius: 16px;
  }

  .link-text-1 {
    font-size: 14px;
    font-weight: bolder;
    color: #f0b90b;
  }

  .links-card-1 {
    padding: 22px 18px;
    background-color: var(--background-color-4);
    border-radius: 8px;
  }

  .dashboard-overall-partners-table {
    overflow: scroll;
  }

  .dashboard-overall-partners-table .rdt_Table {
    background: var(--background-color-4) !important;
  }

  .dashboard-overall-partners-table .rdt_TableHeadRow {
    background: var(--background-color-4) !important;
    color: var(--color-1);
  }

  .dashboard-overall-partners-table .rdt_TableRow {
    background: var(--background-color-4);
    color: var(--color-1);
  }

  .datatable-2-example {
    width: 100% !important;
  }

  #datatable-example {
    width: 120%;
  }

  .dashboard-gold-inner-img-1 {
    width: 26px;
    height: 26px;
  }

  .dashboard-gold-inner-card-2 {
    background: linear-gradient(140deg, #ff9600 48.73%, #ffc600 100%);
    padding: 12px 18px;
    border-radius: 14px;
    color: #000000 !important;
  }

  .dashboard-gold-inner-card-3 {
    padding: 12px 18px;
    background-color: var(--background-color-4);
    border-radius: 14px;
  }

  .dashboard-gold-inner-card-1 {
    padding: 24px 24px;
    background-color: var(--background-color-4);
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    color: var(--color-1) !important;
  }

  .dashboard-gold-inner-card-1:hover {
    background-color: var(--background-color-5);
  }

  .dashboard-user-programs-glow-2 {
    position: absolute;
    bottom: -20%;
    right: 0%;
    width: 280px;
    height: 230px;
    border-radius: 50%;
    filter: blur(100px);
    z-index: 0;
    background-color: #f0bb0b73;
  }

  /* .dashboard-user-programs-glow-3 {
    position: absolute;
    width: 280px;
    height: 230px;
    border-radius: 50%;
    filter: blur(100px);
    z-index: 0;
    left: 30%;
    background-color: var(--background-color-5);
  } */
  .dashboard-x3-card-2 td {
    vertical-align: middle;
  }

  .dashboard-x3-card-2 thead tr th {
    font-size: 12px;
    opacity: 0.6 !important;
    padding: 16px 20px !important;
    font-size: 12px;
  }

  .dashboard-x3-card-2 tbody tr td {
    font-size: 12px;
    opacity: 0.8;
    padding: 10px 20px !important;
    font-size: 14px;
  }

  .dashboard-x3-card-2 .table {
    margin-bottom: 0px;
    /* font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif; */
  }

  .dashboard-x3-card-2 table {
    width: 100%;
    white-space: nowrap;
    overflow: scroll;
  }

  .dashboard-x3-card-2 .table> :not(caption)>*>* {
    border-bottom: 1px solid var(--background-color-5);
  }

  .dashboard-x3-card-2 {
    padding: 0px 0px;
    background-color: var(--background-color-4);
    border-radius: 10px !important;
    overflow: hidden;
  }

  .dashboard-x3-text-1 {
    font-size: 14px;
  }

  .dashboard-x3-card-1-inner-1 {
    background: linear-gradient(140deg, #ff9600 48.73%, #ffc600 100%);
    padding: 12px 18px;
    border-radius: 14px;
    color: #000000 !important;
    min-height: 200px;
  }

  .dashboard-x3-card-1 {
    padding: 24px 24px;
    background-color: var(--background-color-4);
    border-radius: 10px;
  }

  .dashboard-card-3 {
    background-image: url("../../Assets/images/5.png");
    background-size: 400px;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-position: 290px 10px;
  }

  .dashboard-activity-seemore-btn {
    background-color: var(--background-color-5);
    color: var(--color-1);
    font-size: 15px;
    font-weight: 800;
    letter-spacing: 1px;
  }

  .dashboard-activity-seemore-btn:hover {
    background-color: var(--background-color-4);
    color: var(--color-1);
  }

  .dashboard-activity-seemore-btn2 {
    background-color: var(--background-color-5);
    color: var(--color-1);
    font-size: 15px;
    font-weight: 800;
    letter-spacing: 1px;
  }

  .dashboard-activity-seemore-btn2:hover {
    background-color: var(--background-color-4);
    color: var(--color-1);
  }

  .dashboard-activity-2 {
    width: 2.5rem;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
    background-color: rgba(44, 255, 78, 0.2);
    display: flex;
    position: relative;
    border-radius: 50%;
    font-size: 14px;
    color: #2bea4a;
  }

  .dashboard-card-4-inner-1 {
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--background-color-5);
  }

  .dashboard-activity-1 {
    width: 2.5rem;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
    color: var(--color-1);
    background-color: var(--background-color-5);
    display: flex;
    position: relative;
    border-radius: 50%;
    font-size: 14px;
  }

  .dashboard-card-4 {
    padding: 12px 18px;
    background-color: var(--background-color-4);
    border-radius: 10px;
  }

  .dashboard-text-10 {
    position: relative;
    top: -10px;
    color: var(--color-1);
  }

  .dashboard-card-3-inner-1 {
    position: relative;
    z-index: 99;
  }

  /* .dashboard-user-programs-glow-1 {
    position: absolute;
    bottom: -10%;
    right: 0%;
    width: 180px;
    height: 130px;
    border-radius: 50%;
    filter: blur(200px);
    z-index: 0;
    background-color: #f0b90b;
  } */
  .dashboard-partners-gif {
    width: 26px;
    height: auto;
  }

  .dashboard-preview-btn-2 {
    background-color: #f0b90b;
    font-size: 15px;
    font-weight: 900;
    padding: 6px 12px;
    border: none;
    border-radius: 5px;
  }

  .grid-dashboard-container {
    background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(5, auto);
    gap: 10px;
    background-repeat: no-repeat;
    justify-content: start;
  }

  .dashboard-user-programs-active {
    background-color: var(--background-color-2) !important;
  }

  .dashboard-user-programs {
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 0.375rem;
    background-color: lightgray;
  }

  .dark-theme .dashboard-user-programs {
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 0.375rem;
    background-color: var(--background-color-5);
  }

  .dashboard-text-9 {
    font-size: 22px;
    font-weight: bolder;
    color: var(--color-1);
  }

  .dashboard-card-3 {
    padding: 14px 18px;
    background-color: var(--background-color-4);
    border-radius: 10px;
  }

  .dashboard-text-8 {
    font-size: 20px;
    font-weight: bolder;
  }

  .dashboard-card-2-inner-1 {
    background-color: var(--background-color-5);
    border-radius: 20px;
    padding: 10px 5px;
  }

  .dashboard-text-7 {
    font-size: 13px;
    color: var(--color-1);
  }

  .dashboard-card-2 {
    padding: 12px 6px;
    background-color: var(--background-color-3);
    border-radius: 20px;
    /* border-bottom: 1px solid #f0bb0b23; */
  }

  .dashboard-split-right-section .ratio-card {
    width: 150px;
  }

  .dashboard-user-coin-logo {
    width: 35px;
    position: relative;
    left: -20px;
    top: -2px;
    padding: 2px;
  }

  .dashboard-text-6 {
    font-size: 14px;
  }

  .dashboard-card-1-inner-3 {
    background-color: var(--background-color-5);
    padding: 10px 20px;
    color: var(--color-1);
  }

  .dashboard-button-2 {
    background-color: var(--background-color-2);
    padding: 2px 10px;
    border-radius: 12px;
    border: none;
    color: var(--background-color-1);
    font-size: 14px;
    font-weight: 800;
  }

  .dashboard-card-1-inner-2 {
    margin: 0px 20px;
    margin-bottom: 10px;
  }

  .dashboard-text-5 {
    font-size: 16px;
    font-weight: 900;
    color: var(--background-color-2);
  }

  .dashboard-card-1-inner-1 {
    border-bottom: 1px solid var(--background-color-5);
    margin: 15px;
    padding-bottom: 12px;
  }

  .dashboard-card-1 {
    padding: 0px;
    background-color: var(--background-color-4);
    border-radius: 20px;
    overflow: hidden;
  }

  .dashboard-button-1 {
    background-color: var(--background-color-5);
    padding: 2px 10px;
    border-radius: 12px;
    border: none;
    color: var(--background-color-2);
  }

  .dashboard-text-4 {
    font-size: 15px;
    color: var(--color-1);
  }

  .dashboard-text-3 {
    font-size: 16px;
    font-weight: bolder;
  }

  .dashboard-text-2 {
    font-size: 26px;
    font-weight: bolder;
    color: var(--color-1);
  }

  .dashboard-user-profile {
    width: 7.5rem;
    height: 7.5rem;
    outline: 10px solid var(--background-color-5);
    border-radius: 50%;
    background: var(--background-color-5);
  }

  .sidenavbar-active {
    color: #fff !important;
    background-color: var(--background-color-5);
  }

  .dashboard-split-left-section .accordion-body {
    border-top: 1px solid var(--background-color-5);
    padding: 8px;
  }

  .dashboard-split-left-section .accordion-button::after {
    background-size: 12px;
    height: 12px;
    width: 12px;
    background-image: url("../images/images-dash/accrd-arrow.png");
    filter: brightness(0.5) invert(0.5);
  }

  .dashboard-split-left-section .accordion-button:not(.collapsed)::after {
    background-image: url("../images/images-dash/accrd-arrow.png");
  }

  .dashboard-split-left-section .accordion-button:not(.collapsed) {
    color: #ffffffab;
    background-color: transparent;
  }

  .dashboard-split-left-section .accordion-button {
    background-color: transparent;
    padding: 0px;
    color: #ffffffab;
    padding: 8px 8px;
    box-shadow: none !important;
    border-radius: 4px !important;
  }

  .dashboard-split-left-section .accordion-item {
    color: #ffffffab;
    background-color: var(--background-color-4);
    border: none;
    border-radius: 0.5em !important;
  }

  .dashboard-split-section .side-nav {
    height: calc(100vh);
    border-right: 1px solid var(--background-color-4);
    padding-right: 10px;
  }

  .sidenavbar-nav-link {
    margin-bottom: 8px;
    /* background-color: #0e0d0d; */
    padding: 5px 8px;
    border-radius: 4px;
    color: #ffffffab;
    cursor: pointer;
  }

  .sidenavbar-nav-link-2 {
    margin-bottom: 6px;
    color: #ffffffab;
    padding: 0px;
    cursor: pointer;
  }

  .sidenavbar-nav-link-2 li:hover {
    background: var(--background-color-5);
    border-radius: 0.5em;

  }

  .sidenav-text-1 {
    font-size: 15px;
    color: var(--color-1);
  }

  .sidenav-icon-1 {
    font-size: 18px;
    margin: 0px 8px;
    color: var(--background-color-2);
  }

  .sidenav-icon-2 {
    font-size: 14px;
    margin-right: 8px;
  }

  .dashboard-split-left-section {
    position: sticky;
    height: calc(100vh);
    top: 0px;
    left: 0;
    color: white;
    flex-grow: 0;
    flex-shrink: 0;
    padding-top: 65px;
  }

  .dashboard-split-right-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .dashboard-close-btn {
    background-color: var(--background-color-2);
    backdrop-filter: blur(15px);
    border: none;
    border-radius: 50px;
    padding: 2px 8px !important;
    font-size: 20px;
    height: 36px;
  }

  .dashboard-connect-wallet-btn {
    background-color: var(--background-color-4);
    backdrop-filter: blur(15px);
    border: none;
    border-radius: 12px;
    padding-left: 16px !important;
    padding-right: 16px !important;
    font-size: 14px;
  }

  .dashboard-connect-wallet-btn:hover {
    background-color: var(--background-color-5);
  }

  .btn-dashboard-preview-search {
    background-color: var(--background-color-2);
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    border-radius: 12px;
    border: none;
    padding: 4px 10px;
  }

  .btn-dashboard-preview-search:hover {
    background-color: #ffa701;
  }

  .dashboard-text-1 {
    font-size: 14px;
  }

  .dashboard-navbar-section .nav-link {
    color: var(--color-1);
  }

  .dark-theme .dashboard-navbar-section .navbar {
    background-color: var(--background-color-1);
    padding: 4px;
  }

  .dashboard-navbar-section .navbar {
    background-color: var(--background-color-3);
    padding: 4px;
  }

  .navbar-brand-dashboard img {
    width: 175px;
    height: 100%;
    margin-left: -20px;
  }

  .navbar-preview-id-search {
    box-shadow: none;
    background-color: var(--background-color-5);
    color: var(--color-1);
    border: none;
    width: 50%;
  }

  .navbar-preview-id-search:focus {
    box-shadow: none;
    background-color: var(--background-color-5);
    color: var(--color-1);
  }

  .Dailyclaim .card {
    width: 50%;
  }
}

/* ============ MOBILE-VIEW ============ */

@media (max-width: 991px) {

  /* .dashboard-card-3 {
    background-image: url("../../Assets/images/5.png");
    background-size: 400px;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-position: 100px 30px;
  } */
  .dashboard-overall-partners-table .rdt_Table {
    background: var(--background-color-4) !important;
  }

  .dashboard-overall-partners-table .rdt_TableHeadRow {
    background: var(--background-color-4) !important;
    color: var(--color-1);
  }

  .dashboard-overall-partners-table .rdt_TableRow {
    background: var(--background-color-4);
    color: var(--color-1);
  }

  .xcore-inner-total-grid-1 .grid-dashboard-xcore-inner-l1-container {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: #ffc853;
    border-radius: 50%;
    width: 200px;
    display: grid;
    grid-template-columns: auto auto;
    margin: auto;
    gap: 10px;
    font-size: 10px;
  }

  .grid-dashboard-xcore-inner-inner-l2-container {
    background: #fff583;
    border-radius: 50%;
    width: 70px;
    margin-bottom: -110px !important;
    z-index: 99;
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    margin: auto;
    font-size: 10px;
  }

  .link-page-select {
    border: none;
    outline: none;
    background-color: transparent;
    padding: 4px 10px;
    color: #FFF;
    font-size: 14px;
  }

  .link-page-select option {
    color: #000;
  }

  .links-card-2-inner-1 {
    background-color: var(--background-color-5);
    border-radius: 20px;
    padding: 6px 10px;
  }

  .links-card-2 {
    padding: 18px 18px;
    background-color: var(--background-color-4);
    border-radius: 16px;
  }

  .link-text-1 {
    font-size: 14px;
    font-weight: bolder;
    color: #f0b90b;
  }

  .links-card-1 {
    padding: 22px 18px;
    background-color: var(--background-color-4);
    border-radius: 8px;
  }

  .dashboard-overall-partners-table {
    overflow: scroll;
  }

  .datatable-2-example {
    width: 900px !important;
  }

  #datatable-example {
    width: 1200px;
  }

  .dashboard-gold-inner-img-1 {
    width: 26px;
    height: 26px;
  }

  .dashboard-gold-inner-card-2 {
    background: linear-gradient(140deg, #ff9600 48.73%, #ffc600 100%);
    padding: 12px 18px;
    border-radius: 14px;
    color: #000000 !important;
  }

  .dashboard-gold-inner-card-3 {
    padding: 12px 18px;
    background-color: var(--background-color-4);
    border-radius: 14px;
  }

  .dashboard-gold-inner-card-1 {
    padding: 12px 24px;
    background-color: var(--background-color-4);
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    color: var(--color-1) !important;
  }

  .dashboard-gold-inner-card-1:hover {
    background-color: var(--background-color-5);
  }

  .dashboard-user-programs-glow-2 {
    position: absolute;
    bottom: -20%;
    right: 0%;
    width: 280px;
    height: 230px;
    border-radius: 50%;
    filter: blur(100px);
    z-index: 0;
    background-color: #f0bb0b73;
  }

  .dashboard-x3-card-2 table {
    width: 100%;
    white-space: nowrap;
    overflow: scroll;
  }

  .dashboard-x3-card-2 {
    overflow-x: scroll;
  }

  .dashboard-x3-card-2 td {
    vertical-align: middle;
  }

  .dashboard-x3-card-2 thead tr th {
    font-size: 12px;
    opacity: 0.6 !important;
    padding: 16px 20px !important;
    font-size: 12px;
  }

  .dashboard-x3-card-2 tbody tr td {
    font-size: 12px;
    opacity: 0.8;
    padding: 10px 20px !important;
    font-size: 14px;
  }

  .dashboard-x3-card-2 .table {
    margin-bottom: 0px;
    /* font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif; */
  }

  .dashboard-x3-card-2 .table> :not(caption)>*>* {
    border-bottom: 1px solid var(--background-color-5);
  }

  .dashboard-x3-card-2 {
    padding: 0px 0px;
    background-color: var(--background-color-4);
    border-radius: 10px !important;
  }

  .dashboard-x3-text-1 {
    font-size: 14px;
  }

  .dashboard-x3-card-1-inner-1 {
    background: linear-gradient(140deg, #ff9600 48.73%, #ffc600 100%);
    padding: 12px 18px;
    border-radius: 14px;
    color: #000000;
    min-height: 200px;
  }

  .dashboard-x3-card-1 {
    padding: 24px 24px;
    background-color: var(--background-color-4);
    border-radius: 10px;
  }

  /* .dashboard-navbar-section .navbar-toggler {
    color: var(--color-1);
    background-color: var(--background-color-5);
    outline: none;
    padding: 0.25rem 0.75rem;
  } */

  .dashboard-navbar-section .navbar .nav-link {
    margin-top: 20px;
  }

  .dashboard-activity-seemore-btn {
    background-color: var(--background-color-5);
    color: var(--color-1);
    font-size: 15px;
    font-weight: 800;
    letter-spacing: 1px;
  }

  .dashboard-activity-seemore-btn:hover {
    background-color: var(--background-color-4);
    color: var(--color-1);
  }

  .dashboard-activity-seemore-btn2 {
    background-color: var(--background-color-5);
    color: var(--color-1);
    font-size: 15px;
    font-weight: 800;
    letter-spacing: 1px;
    width: 700px;
  }

  .dashboard-activity-seemore-btn2:hover {
    background-color: var(--background-color-4);
    color: var(--color-1);
  }

  .dashboard-activity-2 {
    width: 2rem;
    height: 2rem;
    justify-content: center;
    align-items: center;
    background-color: rgba(44, 255, 78, 0.2);
    display: flex;
    position: relative;
    border-radius: 50%;
    font-size: 14px;
    color: #2bea4a;
  }

  .dashboard-card-4-inner-1 {
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--background-color-5);
    font-size: 12px;
  }

  .dashboard-activity-1 {
    width: 2rem;
    height: 2rem;
    justify-content: center;
    align-items: center;
    color: var(--color-1);
    background-color: var(--background-color-5);
    display: flex;
    position: relative;
    border-radius: 50%;
    font-size: 14px;
  }

  .dashboard-card-4 {
    padding: 12px 18px;
    background-color: var(--background-color-4);
    border-radius: 10px;
  }

  .dashboard-text-10 {
    position: relative;
    top: -10px;
    color: var(--color-1);
  }

  .dashboard-card-3-inner-1 {
    position: relative;
    z-index: 99;
  }

  /* .dashboard-user-programs-glow-1 {
    position: absolute;
    bottom: -10%;
    right: 0%;
    width: 180px;
    height: 130px;
    border-radius: 50%;
    filter: blur(200px);
    z-index: 0;
    background-color: #f0b90b;
  } */
  .dashboard-partners-gif {
    width: 26px;
    height: auto;
  }

  .dashboard-preview-btn-2 {
    background-color: #f0b90b;
    font-size: 15px;
    font-weight: 900;
    padding: 6px 12px;
    border: none;
    border-radius: 5px;
  }

  .grid-dashboard-container {
    background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(5, auto);
    gap: 10px;
    background-repeat: no-repeat;
    justify-content: start;
  }

  .dashboard-user-programs-active {
    background-color: var(--background-color-2) !important;
  }

  .dashboard-user-programs {
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 0.375rem;
    background-color: lightgray;
  }

  .dark-theme .dashboard-user-programs {
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 0.375rem;
    background-color: var(--background-color-5);
  }

  .dashboard-text-9 {
    font-size: 16px;
    font-weight: bolder;
    color: var(--color-1);
  }

  .dashboard-card-3 {
    padding: 14px 18px;
    background-color: var(--background-color-4);
    border-radius: 10px;
  }

  .dashboard-text-8 {
    font-size: 20px;
    font-weight: bolder;
  }

  .dashboard-card-2-inner-1 {
    background-color: var(--background-color-5);
    border-radius: 20px;
    padding: 6px 10px;
  }

  .dashboard-text-7 {
    font-size: 13px;
    color: var(--color-1);
  }

  .dashboard-card-2 {
    padding: 12px 6px;
    background-color: var(--background-color-3);
    border-radius: 20px;
    /* border-bottom: 1px solid #f0bb0b23; */
  }

  .dashboard-user-coin-logo {
    width: 30px;
    border-radius: 50%;
    position: relative;
    left: -20px;
    top: -2px;
    /* background-color: #333333; */
    padding: 2px;
  }

  .dashboard-text-6 {
    font-size: 14px;
  }

  .dashboard-card-1-inner-3 {
    background-color: var(--background-color-5);
    padding: 10px 20px;
    color: var(--color-1);
  }

  .dashboard-button-2 {
    background-color: var(--background-color-2);
    padding: 2px 10px;
    border-radius: 12px;
    border: none;
    color: var(--background-color-1);
    font-size: 14px;
    font-weight: 800;
  }

  .dashboard-card-1-inner-2 {
    margin: 0px 20px;
    margin-bottom: 10px;
  }

  .dashboard-text-5 {
    font-size: 12px;
    font-weight: 900;
    color: var(--background-color-2);
  }

  .dashboard-card-1-inner-1 {
    border-bottom: 1px solid var(--background-color-5);
    margin: 15px;
    padding-bottom: 12px;
  }

  .dashboard-card-1 {
    padding: 0px;
    background-color: var(--background-color-4);
    border-radius: 20px;
    overflow: hidden;
  }

  .dashboard-button-1 {
    background-color: var(--background-color-5);
    padding: 2px 10px;
    border-radius: 12px;
    border: none;
    color: var(--background-color-2);
    font-size: 14px;
  }

  .dashboard-text-4 {
    font-size: 15px;
    color: var(--color-1);
  }

  .dashboard-text-3 {
    font-size: 16px;
    font-weight: bolder;
  }

  .dashboard-text-2 {
    font-size: 20px;
    font-weight: bolder;
    color: var(--color-1);
  }

  .dashboard-user-profile {
    width: 7.5rem;
    height: 7.5rem;
    outline: 10px solid var(--background-color-5);
    border-radius: 50%;
    background: var(--background-color-5);
  }

  .sidenavbar-active {
    color: #fff !important;
    background-color: var(--background-color-5);
  }

  .dashboard-split-left-section .accordion-body {
    border-top: 1px solid var(--background-color-5);
    padding: 8px;
  }

  .dashboard-split-left-section .accordion-button::after {
    background-size: 12px;
    height: 12px;
    width: 12px;
    background-image: url("../images/images-dash/accrd-arrow.png");
    filter: brightness(0.5) invert(0.5);
  }

  .dashboard-split-left-section .accordion-button:not(.collapsed)::after {
    background-image: url("../images/images-dash/accrd-arrow.png");
  }

  .dashboard-split-left-section .accordion-button:not(.collapsed) {
    color: #ffffffab;
    background-color: transparent;
  }

  .dashboard-split-left-section .accordion-button {
    background-color: transparent;
    padding: 0px;
    color: #ffffffab;
    padding: 8px 8px;
    box-shadow: none !important;
    border-radius: 4px !important;
  }

  .dashboard-split-left-section .accordion-item {
    color: #ffffffab;
    background-color: var(--background-color-4);
    border: none;
    border-radius: 0.5em !important;
  }

  .sidenavbar-nav-link {
    margin-bottom: 8px;
    /* background-color: #0e0d0d; */
    padding: 5px 8px;
    border-radius: 4px;
    color: #ffffffab;
  }

  .sidenavbar-nav-link-2 {
    margin-bottom: 6px;
    color: #ffffffab;
    padding: 0px;
  }

  .sidenav-text-1 {
    font-size: 15px;
    color: var(--color-1);
  }

  .sidenav-icon-1 {
    font-size: 18px;
    margin-right: 10px;
    color: var(--background-color-2);
  }

  .sidenav-icon-2 {
    font-size: 14px;
    margin-right: 8px;
  }

  /* .dashboard-split-left-section {
    position: sticky;
    border-right: 1px solid #0e0d0d;
    height: calc(100vh);
    top: 0px;
    left: 0;
    color: white;
    flex-grow: 0;
    flex-shrink: 0;
    padding-top: 110px;
  } */

  .dashboard-split-right-section {
    padding-top: 50px;
    padding-bottom: 100px;
  }

  .dashboard-close-btn {
    background-color: #ffa60180;
    backdrop-filter: blur(15px);
    border: none;
    border-radius: 6px;
    border-radius: 12px;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .dashboard-connect-wallet-btn {
    background-color: var(--background-color-4);
    backdrop-filter: blur(15px);
    border: none;
    border-radius: 12px;
    padding-left: 16px !important;
    padding-right: 16px !important;
    font-size: 14px;
    width: max-content;
  }

  .dashboard-connect-wallet-btn:hover {
    background-color: rgba(27, 159, 254, 0.178);
  }

  .btn-dashboard-preview-search {
    background-color: var(--background-color-2);
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    border-radius: 12px;
    border: none;
    width: max-content;
  }

  .btn-dashboard-preview-search:hover {
    background-color: #ffa701;
  }

  .dashboard-text-1 {
    font-size: 14px;
  }

  .dashboard-navbar-section .nav-link {
    color: var(--color-1);
  }

  .navbar .container {
    background-color: var(--background-color-1);
    padding: 4px;
    border-radius: 14px;
  }

  .navbar-brand-dashboard img {
    width: 120px;
    height: 100%;
  }

  .navbar-preview-id-search {
    box-shadow: none;
    background-color: var(--background-color-5);
    color: var(--color-1);
    border: none;
  }

  .navbar-preview-id-search:focus {
    box-shadow: none;
    background-color: var(--background-color-5);
    color: var(--color-1);
  }

  .Dailyclaim .card {
    width: 100%;
  }
}

/* <<<<<<<<<<<<<<<<<<<<<<<GRID>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* ============ DESKTOP-VIEW ============ */

@media all and (min-width: 992px) {

  /* .xcore-inner-total-grid-1 {
    background-color: #ffc853;
    border-radius: 50%;
    width: 240px;
    margin: auto;
  } */
  /* .xcore-inner-total-grid-2 {
    background: #fff583;
    border-radius: 50%;
    width: 90px;
    margin: auto;
    margin-bottom: -140px;
    z-index: 99;
    position: relative;
  } */
  .xcore-inner-total-grid-overrall {
    margin-top: 100px;
  }

  .dashboard-user-xcore-inner-all-level-programs {
    width: 2.1rem;
    height: 2.1rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c1c1c1d4;
  }

  .dashboard-user-xcore-inner-all-level-2-programs {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    background-color: #c1c1c1d4;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dashboard-user-xcore-inner-programs-active {
    background-color: #fff !important;
  }

  .dashboard-user-xcore-inner-position-left-1 {
    position: relative;
    left: -14px;
  }

  .dashboard-user-xcore-inner-position-right-1 {
    position: relative;
    left: 14px;
  }

  .grid-dashboard-xcore-inner-inner-l1-container {
    background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(1, auto);
    background-repeat: no-repeat;
    justify-content: center;
    position: relative;
    top: -18px;
  }

  /* .grid-dashboard-xcore-inner-inner-l2-container {
    background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(2, auto);
    background-repeat: no-repeat;
    justify-content: space-between;
    margin-top: 10px;
  } */

  /* .grid-dashboard-xcore-inner-l1-container {
    background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(2, auto);
    background-repeat: no-repeat;
    justify-content: center;
    position: relative;
    top: -8px;

  } */
  .grid-dashboard-xcore-inner-l2-container {
    background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(2, auto);
    background-repeat: no-repeat;
    justify-content: space-between;
    position: relative;
    width: 100%;
    margin: auto;
    margin-bottom: 10px;
  }

  .grid-dashboard-xcore-inner-l3-container {
    background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(2, auto);
    background-repeat: no-repeat;
    justify-content: space-between;
    position: relative;
    top: -22px;
    width: 100%;
    margin: auto;
    margin-top: 60px;
  }

  .grid-dashboard-xcore-inner-l4-container {
    background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(2, auto);
    background-repeat: no-repeat;
    justify-content: space-between;
    position: relative;
    top: -2px;
    width: 100%;
    margin: auto;
  }

  .grid-dashboard-xcore-inner-l5-container {
    background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(2, auto);
    background-repeat: no-repeat;
    justify-content: space-between;
    position: relative;
    top: 4px;
    width: 50%;
    margin: auto;
  }

  .dashboard-user-gold-inner-l1-programs {
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
    background-color: #5252522f;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 12px;
  }

  .grid-dashboard-gold-inner-l1-container {
    background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 10px;
    background-repeat: no-repeat;
    justify-content: space-around;
  }

  .dashboard-user-gold-inner-l2-programs {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    background-color: #5252522f;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 12px;
  }

  .grid-dashboard-gold-inner-l2-container {
    background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 8px;
    background-repeat: no-repeat;
    justify-content: space-around;
  }

  .dashboard-user-gold-inner-l3-programs {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: #5252522f;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 10px;
  }

  .grid-dashboard-gold-inner-l3-container {
    background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(8, auto);
    gap: 6px;
    background-repeat: no-repeat;
    justify-content: space-around;
  }

  .dashboard-user-gold-inner-l4-programs {
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    background-color: #5252522f;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 8px;
    transition: transform 0.1s;
    /* Animation */
  }

  .grid-dashboard-gold-inner-l4-container {
    background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(16, auto);
    gap: 2px;
    background-repeat: no-repeat;
    justify-content: space-around;
  }

  .dashboard-user-gold-inner-l4-programs.dashboard-user-gold-inner-programs-active:hover {
    transform: scale(2);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .dashboard-user-gold-inner-l4-programs.dashboard-user-gold-inner-programs-active:hover .grid-dashboard-gold-inner-l4-user-id {
    display: block;
  }

  .dashboard-user-gold-inner-l4-programs.dashboard-user-gold-inner-programs-active:hover .grid-dashboard-gold-inner-l4-user-icon {
    display: none;
  }

  .grid-dashboard-gold-inner-l4-user-id {
    display: none;
    font-size: 5px;
  }

  .grid-dashboard-gold-inner-l4-user-icon {
    display: block;
  }

  .dashboard-user-gold-inner-programs-active {
    background-color: #fff !important;
  }
}

/* ============ MOBILE-VIEW ============ */

@media (max-width: 991px) {

  /* .xcore-inner-total-grid-1 {
    background-color: #ffc853;
    border-radius: 50%;
    width: 75%;
    margin: auto;
  } */
  /* .xcore-inner-total-grid-2 {
    background: #fff583;
    border-radius: 50%;
    width: 30%;
    margin: auto;
    margin-bottom: -130px;
    z-index: 99;
    position: relative;
  } */
  .xcore-inner-total-grid-overrall {
    margin-top: 100px;
  }

  .dashboard-user-xcore-inner-all-level-programs {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c1c1c1d4;
  }

  .dashboard-user-xcore-inner-all-level-2-programs {
    width: 1.9rem;
    height: 1.9rem;
    border-radius: 50%;
    background-color: #c1c1c1d4;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dashboard-user-xcore-inner-programs-active {
    background-color: #fff !important;
  }

  .dashboard-user-xcore-inner-position-left-1 {
    position: relative;
    left: -10px;
  }

  .dashboard-user-xcore-inner-position-right-1 {
    position: relative;
    left: 10px;
  }

  .grid-dashboard-xcore-inner-inner-l1-container {
    background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(1, auto);
    background-repeat: no-repeat;
    justify-content: center;
    position: relative;
    top: -18px;
  }

  /* .grid-dashboard-xcore-inner-inner-l2-container {
    background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(2, auto);
    background-repeat: no-repeat;
    justify-content: space-between;
    margin-top: 10px;
  } */

  /* .grid-dashboard-xcore-inner-l1-container {
    background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(2, auto);
    background-repeat: no-repeat;
    justify-content: center;
    position: relative;
    top: -8px;
    margin-bottom: 10px;
  } */
  .grid-dashboard-xcore-inner-l2-container {
    background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(2, auto);
    background-repeat: no-repeat;
    justify-content: space-between;
    position: relative;
    width: 96%;
    margin: auto;
    margin-bottom: 16px;
  }

  .grid-dashboard-xcore-inner-l3-container {
    background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(2, auto);
    background-repeat: no-repeat;
    justify-content: space-between;
    position: relative;
    top: -22px;
    width: 100%;
    margin: auto;
    margin-top: 60px;
  }

  .grid-dashboard-xcore-inner-l4-container {
    background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(2, auto);
    background-repeat: no-repeat;
    justify-content: space-between;
    position: relative;
    top: -2px;
    width: 90%;
    margin: auto;
  }

  .grid-dashboard-xcore-inner-l5-container {
    background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(2, auto);
    background-repeat: no-repeat;
    justify-content: space-between;
    position: relative;
    top: 4px;
    width: 50%;
    margin: auto;
  }

  .dashboard-user-gold-inner-l1-programs {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    background-color: #5252522f;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 12px;
  }

  .grid-dashboard-gold-inner-l1-container {
    background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 6px;
    background-repeat: no-repeat;
    justify-content: space-around;
  }

  .dashboard-user-gold-inner-l2-programs {
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    background-color: #5252522f;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 10px;
  }

  .grid-dashboard-gold-inner-l2-container {
    background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 6px;
    background-repeat: no-repeat;
    justify-content: space-around;
  }

  .dashboard-user-gold-inner-l3-programs {
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    background-color: #5252522f;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 8px;
  }

  .grid-dashboard-gold-inner-l3-container {
    background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(8, auto);
    gap: 4px;
    background-repeat: no-repeat;
    justify-content: space-around;
  }

  .dashboard-user-gold-inner-l4-programs {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #5252522f;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 6px;
    transition: transform 0.1s;
    /* Animation */
  }

  .grid-dashboard-gold-inner-l4-container {
    background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(16, auto);
    gap: 2px;
    background-repeat: no-repeat;
    justify-content: space-around;
  }

  .dashboard-user-gold-inner-l4-programs.dashboard-user-gold-inner-programs-active:hover {
    transform: scale(2);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .dashboard-user-gold-inner-l4-programs.dashboard-user-gold-inner-programs-active:hover .grid-dashboard-gold-inner-l4-user-id {
    display: block;
  }

  .dashboard-user-gold-inner-l4-programs.dashboard-user-gold-inner-programs-active:hover .grid-dashboard-gold-inner-l4-user-icon {
    display: none;
  }

  .grid-dashboard-gold-inner-l4-user-id {
    display: none;
    font-size: 5px;
  }

  .grid-dashboard-gold-inner-l4-user-icon {
    display: block;
  }

  .dashboard-user-gold-inner-programs-active {
    background-color: #fff !important;
  }
}






/* <<<<<<<<<<<<<<<<<<<<<<<<<<<Pandi >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.dashboard-calculator-section .bg-dark {
  background-color: var(--background-color-4) !important;
}

/* .dashboard-calculator-section .dashbanner1 h4 {
  font-size: 48px;
  font-weight: bolder;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  letter-spacing: 2px;
  background-image: linear-gradient(180deg, #FAD961 0%, #F76B1C 100%);
} */

/* .dashboard-calculator-section .dashbanner1 h3 {
  font-size: 48px;
  font-weight: bolder;
} */

.dashboard-calculator-section .dashrounding {
  border-radius: 20px;
}

.dashboard-calculator-section .dashbanner20 {
  font-size: 14px;
}

.dashboard-calculator-section .dashbanner2 {
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 15px;
}

.dashboard-calculator-section .dashbanner2 h4 {
  font-size: 18px;
}

.dashboard-calculator-section .logo {
  background-color: #d03a94;
  font-size: 30px;
  border-radius: 15px;
}

.dashboard-calculator-section .thirdbtn0 {
  width: 60px;
  height: 60px;
  background: var(--background-color-6) !important;
  color: var(--color-1);
  border: 0;
  outline: none;
  border-radius: 15px;
}

.dashboard-calculator-section .getting1 {
  width: 60px;
  height: 60px;
  background-color: rgb(208, 58, 148) !important;
  color: WHITE;
  border-radius: 15px;
}

.dashboard-calculator-section .thirdbtn1 {
  width: 50px;
  height: 50px;
  background: var(--background-color-6) !important;
  color: var(--color-1);
  border: 0;
  outline: none;
  border-radius: 15px;
  letter-spacing: 1px;
  font-size: 20px;
}

.dashboard-calculator-section .getting {
  width: 50px;
  height: 50px;
  background: var(--background-color-2) !important;
  color: WHITE;
  letter-spacing: 1px;
  font-size: 20px;
}

.dashboard-calculator-section .dashbannerchild0 {
  font-size: 16px;
  font-weight: bolder;
}

.dashboard-calculator-section .dashbannerchild01 {
  font-size: 26px;
  font-weight: bolder;
}

.dashboard-calculator-section .logo1 {
  font-size: 16px;
  background-color: rgb(255, 162, 67);
  border-radius: 15px;
}

.dashboard-calculator-section .fourthbanner {
  border-radius: 15px;
  /* background-color: #622aff; */
  background-color: var(--background-color-4);
}

.dashboard-calculator-section .fourthbannerchild1 h4 {
  font-size: 26px;
  font-weight: bolder;
  color: var(--color-1);
}

.dashboard-calculator-section .fourthbannerchild2 h4 {
  font-size: 26px;
  font-weight: bolder;
  color: var(--color-1);
}

.dashboard-calculator-section .fifthchild0 {
  font-size: 12px;
}

.dashboard-calculator-section .levelincome-table {}

.dashboard-calculator-section .levelincome-table th {
  font-size: 12px;
}

.dashboard-calculator-section .levelincome-table td {
  font-size: 14px;
  white-space: nowrap;
}

/* <---------------images-section------------> */
/* .dollar-images-hold0{
  width: 175px;
  height: 175px;
 } */
/* .dollar-images{
  display: none; 
 }
 .dollar-images .activate{
  display: block;
 } */
.dollar-images-hold0 img {
  width: 150px;
  height: 165px;
}

.border-triangle0:hover {
  transform: translateY(13px);
  transition: 0.5s;
}

.small-icons-images img {
  transition: 0.5s;
}


.small-icons-images img:hover {
  transform: translateY(-10px);
  transition: 0.5s;
}


.dashboard-split-right-section .dollar-images:nth-child(1) .small-icons-images img {
  width: 35px;
  height: 35px;
}

.dashboard-split-right-section .dollar-images:nth-child(2) .small-icons-images img {
  width: 35px;
  height: 35px;
}

.dashboard-split-right-section .dollar-images:nth-child(3) .small-icons-images img {
  width: 35px;
  height: 35px;
}

.dashboard-split-right-section .dollar-images:nth-child(4) .small-icons-images img {
  width: 35px;
  height: 35px;
}

/* .whole-box-content .activate{
  visibility: visible;
 }
 .dollar-images{
  visibility:hidden;
 } */
.dollar-images-hold0:hover img {
  animation: rotate 1s linear;
}

@keyframes rotate {
  0% {
    transform: rotatey(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* <------------------images-section-end---------> */

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<Pandi >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */



/* <<<<<<<<<<<<<<<<<<<<<<<<<<<Amirtha >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

@media all and (min-width: 992px) {
  .banner-section1 .collapse:not(.show) {
    display: block !important;
  }

  /* Desktop */
  .dashboard-instructions-section .banner-section1-card {
    background-color: var(--background-color-4) !important;
    padding: 1.875rem;
    border-radius: 16px;
  }

  .dashboard-instructions-section .banner-section1-card1 {
    background-color: var(--background-color-4);
    padding: 1.875rem;
    border-radius: 16px;
    margin-top: 20px !important;
  }

  .dashboard-instructions-section .banner-section1-card2 {
    background-color: var(--background-color-3);
    padding: 1rem;
    border-radius: 16px;
  }

  .dark-theme .dashboard-instructions-section .banner-section1-card2 {
    background-color: var(--background-color-5);
    padding: 1rem;
    border-radius: 16px;
  }

  .dashboard-instructions-section .card-iframe-align {
    width: 200px !important;
  }

  .dashboard-instructions-section .introduction-activity-1 {
    width: 4rem;
    height: 2rem;
    justify-content: center;
    align-items: center;
    background-color: #f8f1f1;
    display: flex;
    position: relative;
    border-radius: 50%;
    font-size: 14px;
    color: rgb(40, 3, 252);
  }

  .dashboard-instructions-section .introduction-activity-2 {
    width: 9rem;
    height: 2rem;
    justify-content: center;
    align-items: center;
    background-color: #777373;
    display: flex;
    position: relative;
    border-radius: 50%;
    font-size: 14px;
    color: rgb(238, 237, 243);
  }

  .dashboard-instructions-section .introduction-activity-3 {
    width: 2rem;
    height: 2rem;
    justify-content: center;
    align-items: center;
    background-color: rgb(167, 160, 207);
    display: flex;
    position: relative;
    border-radius: 50%;
    font-size: 14px;
    color: rgb(84, 76, 154);
  }

  .dashboard-instructions-section .introduction-activity-4 {
    width: 4rem;
    height: 2rem;
    justify-content: center;
    align-items: center;
    background-color: rgb(243, 243, 245);
    display: flex;
    position: relative;
    border-radius: 50%;
    font-size: 14px;
    color: rgb(84, 76, 154);
  }

  .dashboard-instructions-section .introduction-activity-5 {
    width: 4rem;
    height: 2rem;
    justify-content: center;
    align-items: center;
    background-color: rgb(177, 171, 230);
    display: flex;
    position: relative;
    border-radius: 50%;
    font-size: 14px;
    color: white;
  }

  .dashboard-instructions-section .introduction-activity-6 {
    width: 3.5rem;
    height: 1.5rem;
    background-color: rgb(244, 243, 248);
    border-radius: 50%;
    color: white;
  }

  .dashboard-instructions-section .introduction-activity-7 {
    width: 4.5rem;
    height: 1.5rem;
    background-color: rgb(214, 171, 30);
    border-radius: 50%;
    color: white;
  }

  .dashboard-instructions-section .introduction-activity-8 {
    width: 1.5rem;
    height: 1.5rem;
    background-color: rgb(199, 109, 36);
    border-radius: 50%;
    color: white;
  }

  .dashboard-instructions-section .introduction-activity-9 {
    width: 5rem;
    height: 1.5rem;
    background-color: rgb(15, 194, 194);
    border-radius: 50%;
    color: white;
  }

  .dashboard-instructions-section .iframe-video {
    width: 460;
    height: 270px !important;
    ;
  }

  .dashboard-instructions-section .dropdown-hidedesktop {
    display: none !important;
  }

}


@media (max-width: 991px) {

  /* Mobile */
  .dashboard-instructions-section .banner-section1-card {
    background-color: var(--background-color-4);
    padding: 0.875rem;
  }

  .dashboard-instructions-section .banner-section1-card1 {

    margin-top: 20px;
    padding: 0.25rem;
  }

  .banner-section1-card2 {
    background-color: #323131;
  }

  .dashboard-instructions-section .banner-section1-card1 {
    background-color: var(--background-color-4);
    padding: 0.875rem;
    border-radius: 16px;
    margin-top: 10px !important;
  }

  .dashboard-instructions-section .banner-section1-card2 {
    background-color: var(--background-color-3);
    padding: 1rem;
    border-radius: 16px;
  }

  .dark-theme .dashboard-instructions-section .banner-section1-card2 {
    background-color: var(--background-color-5);
    padding: 1rem;
    border-radius: 16px;
  }

  /* #simple-list-example{
        display: none;
    }
    .stricky-notscroll{
        display: none !important;
    } */
  .dashboard-instructions-section .dropdown-hidedesktop .btn {
    --bs-btn-padding-x: none;
    --bs-btn-padding-y: none;
    padding-left: 105px;
    padding-right: 105px;
    padding-top: 10px;
    padding-bottom: 10px;
    --bs-btn-border-radius: 20px;
    background-color: rgb(201, 161, 29);
  }

  .dashboard-instructions-section .banner-section1 .btn-primary {
    --bs-btn-border-color: none;
    margin-bottom: 15px;
    --bs-btn-border-radius: 20px;
    margin-top: 10px;
  }

  .dashboard-instructions-section .banner-section1 .btn {
    text-align: left;
    background: var(--background-color-4) !important;
    color: var(--color-1);
  }

  .dashboard-instructions-section .banner-section1 .btn:hover {
    background-color: #242526;
    border-color: #242526;
  }

  .dashboard-instructions-section .collapse-iconalign {
    margin-left: 20px;
  }
}

.dashboard-instructions-section .banner-section1-textcolor1 {
  color: rgb(138, 132, 132) !important;
}

.dashboard-instructions-section .banner-section1-textcolor2 {
  color: rgb(153, 147, 147) !important;
}

.dashboard-instructions-section .banner-section1-textcolor2:hover {
  color: var(--color-1) !important;
}

.dashboard-instructions-section .stricky-notscroll {
  overflow: auto;
  position: -webkit-sticky;
  position: sticky;
  top: 25%;
}

.dashboard-instructions-section .bnb-logo {
  width: 1.25rem;
  margin-right: 5px;
  height: auto;
}

.dashboard-instructions-section .banner-section1 .card-body {
  padding: 0;
}

.dashboard-instructions-section ul {
  list-style-type: none;
}

.card {
  color: #FFF;
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<Amirtha >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.dashboard-x3-card-2 .table>:not(caption)>*>* {
  background-color: var(--background-color-3);
  color: var(--color-1);
}

.sidenavbar-active {
  color: #fff !important;
  background-color: var(--background-color-4);
  border-radius: 0.5em;
  cursor: pointer;
}

.sidenavbar-active:hover {
  background: var(--background-color-5);
}

.grid-dashboard-xcore-inner-l1-container .dashboard-user-xcore-inner-all-level-programs:nth-child(1) {
  grid-column: 1 / span 2;
  margin: auto;
  position: relative;
  top: -18px;
}

.grid-dashboard-xcore-inner-l1-container .dashboard-user-xcore-inner-all-level-programs:nth-child(2) {
  margin: auto;
  position: relative;
  top: -24px;
  left: -24px;
}

.grid-dashboard-xcore-inner-l1-container .dashboard-user-xcore-inner-all-level-programs:nth-child(3) {
  margin: auto;
  position: relative;
  top: -24px;
  right: -24px;
}

.grid-dashboard-xcore-inner-l1-container .dashboard-user-xcore-inner-all-level-programs:nth-child(4) {
  margin-right: auto;
  position: relative;
  top: -14px;
  left: -14px;
}

.grid-dashboard-xcore-inner-l1-container .dashboard-user-xcore-inner-all-level-programs:nth-child(5) {
  margin-left: auto;
  position: relative;
  top: -14px;
  right: -14px;
}

.grid-dashboard-xcore-inner-l1-container .dashboard-user-xcore-inner-all-level-programs:nth-child(6) {
  margin-right: auto;
  position: relative;
  top: -4px;
}

.grid-dashboard-xcore-inner-l1-container .dashboard-user-xcore-inner-all-level-programs:nth-child(7) {
  margin-left: auto;
  position: relative;
  top: -4px;
}

.grid-dashboard-xcore-inner-l1-container .dashboard-user-xcore-inner-all-level-programs:nth-child(8) {
  margin: auto;
  position: relative;
  right: -14px;
  top: 4px;
}

.grid-dashboard-xcore-inner-l1-container .dashboard-user-xcore-inner-all-level-programs:nth-child(9) {
  margin: auto;
  position: relative;
  left: -14px;
  top: 4px;
}

.grid-dashboard-xcore-inner-inner-l2-container .dashboard-user-xcore-inner-all-level-2-programs:nth-child(1) {
  grid-column: 1 / span 2;
  margin: auto;
  position: relative;
  top: -14px;
}

.grid-dashboard-xcore-inner-inner-l2-container .dashboard-user-xcore-inner-all-level-2-programs:nth-child(2) {
  margin: auto;
  position: relative;
  left: -14px;
}

.grid-dashboard-xcore-inner-inner-l2-container .dashboard-user-xcore-inner-all-level-2-programs:nth-child(3) {
  margin: auto;
  position: relative;
  right: -14px;
}

.dark-light-icon {
  color: var(--color-1);
  font-size: 40px;
  padding: 10px;
  border-radius: 50%;
  background: var(--background-color-5);

}

.dark-light-icon:hover {
  color: var(--color-1);
  font-size: 40px;
  background: var(--background-color-2);
  padding: 10px;
  border-radius: 50%;
  border: 1px solid var(--background-color-2);
}

.dark-light-icon:active {
  background: var(--background-color-2);
}

.dashboard-split-right-section .copy {
  color: var(--color-1);
}

.dashboard-split-right-section .card {
  border: none;
  margin: 0 auto;
}

.dashboard-split-right-section .card-one {
  max-height: 500px;
  overflow-y: scroll;
}

.dashboard-split-right-section .dusd {
  color: var(--color-1);
}

.dashboard-split-right-section .falink {
  color: var(--color-1);
}

.dashboard-instructions-section .textwhite {
  color: var(--color-1);
  margin: 3px;
}

.dashboard-calculator-section .textwhite {
  color: var(--color-1);
}

.dashboard-x3-card-2 .table .textwhite {
  color: var(--color-1);
}

.dashboard-split-right-section .icon-cl {
  color: var(--color-1);
}

.grid-dashboard-xcore-l2-container .dashboard-user-xcore-all-level-2-programs:nth-child(1) {
  grid-column: 1 / span 2;
  margin: auto;
  position: relative;
  top: -8px;
}

.grid-dashboard-xcore-l2-container .dashboard-user-xcore-all-level-2-programs:nth-child(2) {
  margin: auto;
  position: relative;
  left: -8px;
}

.grid-dashboard-xcore-l2-container .dashboard-user-xcore-all-level-2-programs:nth-child(3) {
  margin: auto;
  position: relative;
  right: -8px;
}

.grid-dashboard-xcore-l1-container .dashboard-user-xcore-all-level-programs:nth-child(1) {
  grid-column: 1 / span 2;
  margin: auto;
  position: relative;
  top: -8px;
}

.grid-dashboard-xcore-l1-container .dashboard-user-xcore-all-level-programs:nth-child(2) {
  margin: auto;
  position: relative;
  top: -10px;
  left: -10px;
}

.grid-dashboard-xcore-l1-container .dashboard-user-xcore-all-level-programs:nth-child(3) {
  margin: auto;
  position: relative;
  top: -10px;
  right: -10px;
}

.grid-dashboard-xcore-l1-container .dashboard-user-xcore-all-level-programs:nth-child(4) {
  margin-right: auto;
  position: relative;
  top: -6px;
  left: -6px;
}

.grid-dashboard-xcore-l1-container .dashboard-user-xcore-all-level-programs:nth-child(5) {
  margin-left: auto;
  position: relative;
  top: -6px;
  right: -6px;
}

.grid-dashboard-xcore-l1-container .dashboard-user-xcore-all-level-programs:nth-child(6) {
  margin-right: auto;
  position: relative;
  top: 1px;
}

.grid-dashboard-xcore-l1-container .dashboard-user-xcore-all-level-programs:nth-child(7) {
  margin-left: auto;
  position: relative;
  top: 1px;
}

.grid-dashboard-xcore-l1-container .dashboard-user-xcore-all-level-programs:nth-child(8) {
  margin: auto;
  position: relative;
  right: -8px;
  top: 4px;
}

.grid-dashboard-xcore-l1-container .dashboard-user-xcore-all-level-programs:nth-child(9) {
  margin: auto;
  position: relative;
  left: -8px;
  top: 4px;
}

.x-core-buy-btn {
  margin: auto;
  margin-top: 50px;
}

.x-core-buy-btn button {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.x-core-buy-btn-two {
  margin: auto;
}

.x-core-buy-btn-two button {
  width: auto;
  text-align: center;
  font-weight: 500;
  font-size: 10px;
}

.xcoreinner-total-grid-overrall {
  margin-top: 100px;
}

.xcoreinner-total-grid-2 {
  background: #fff583;
  border-radius: 50%;
  width: 150px;
  margin: auto;
  margin-bottom: -250px;
  z-index: 99;
  position: relative;
  height: 150px;
}

.grid-dashboard-xcoreinner-l2-container {
  background: #fff583;
  border-radius: 50%;
  width: 150px;
  margin-bottom: -86px !important;
  z-index: 99;
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  margin: auto;
  font-size: 10px;
  color: #000;
  height: 150px;
}

.dashboard-user-xcoreinner-all-level-2-programs {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #c1c1c1d4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-user-xcoreinner-programs-active {
  background-color: #fff !important;
}

.xcoreinner-total-grid-1 {
  background-color: #ffc853;
  border-radius: 50%;
  margin: auto;
  width: 300px;
  height: 300px;
}

.grid-dashboard-xcoreinner-l1-container {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #ffc853;
  border-radius: 50%;
  display: grid;
  grid-template-columns: auto auto;
  margin: auto;
  gap: 4px;
  font-size: 10px;
  margin-top: 20px;
  color: #000;
  width: 300px;
  height: 300px;
}

.dashboard-user-xcoreinner-all-level-programs {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #c1c1c1d4 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-dashboard-xcoreinner-l1-container .dashboard-user-xcoreinner-all-level-programs:nth-child(4) {
  margin-right: auto;
  position: relative;
  top: -6px;
  left: -10px;
}

.grid-dashboard-xcoreinner-l1-container .dashboard-user-xcoreinner-all-level-programs:nth-child(5) {
  margin-left: auto;
  position: relative;
  top: -6px;
  right: -10px;
}

.grid-dashboard-xcoreinner-l1-container .dashboard-user-xcoreinner-all-level-programs:nth-child(6) {
  margin-right: auto;
  position: relative;
  top: 15px;
}

.grid-dashboard-xcoreinner-l1-container .dashboard-user-xcoreinner-all-level-programs:nth-child(7) {
  margin-left: auto;
  position: relative;
  top: 15px;
}

.grid-dashboard-xcoreinner-l1-container .dashboard-user-xcoreinner-all-level-programs:nth-child(8) {
  margin: auto;
  position: relative;
  right: -8px;
  top: 10px;
}

.grid-dashboard-xcoreinner-l1-container .dashboard-user-xcoreinner-all-level-programs:nth-child(9) {
  margin: auto;
  position: relative;
  left: -8px;
  top: 10px;
}

.dashboard-user-xcoreinner-all-level-programs-active {
  background-color: #fff !important;
}

.dashboard-navbar-section .spinner-grow {
  --bs-spinner-width: 1.2rem;
  --bs-spinner-height: 1.1rem;
}

/* ============ DESKTOP-VIEW ============ */

@media all and (min-width: 992px) {
  .dashboard-text-4 {
    font-size: 15px;
    color: var(--color-1);
    background: transparent;
    border: none;
  }

  .dashboard-gold-inner-sm-img-1 {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }

  .grid-dashboard-xcoreinner-l1-container .dashboard-user-xcoreinner-all-level-programs:nth-child(1) {
    grid-column: 1 / span 2;
    margin: auto;
    position: relative;
    top: -25px;
  }

  .grid-dashboard-xcoreinner-l1-container .dashboard-user-xcoreinner-all-level-programs:nth-child(2) {
    margin: auto;
    position: relative;
    top: -35px;
    left: -35px;
  }

  .grid-dashboard-xcoreinner-l1-container .dashboard-user-xcoreinner-all-level-programs:nth-child(3) {
    margin: auto;
    position: relative;
    top: -35px;
    right: -35px;
  }

  .grid-dashboard-xcoreinner-l2-container .dashboard-user-xcoreinner-all-level-2-programs:nth-child(1) {
    grid-column: 1 / span 2;
    margin: auto;
    position: relative;
    top: -35px;
  }

  .grid-dashboard-xcoreinner-l2-container .dashboard-user-xcoreinner-all-level-2-programs:nth-child(2) {
    margin: auto;
    position: relative;
    left: -25px;
  }

  .grid-dashboard-xcoreinner-l2-container .dashboard-user-xcoreinner-all-level-2-programs:nth-child(3) {
    margin: auto;
    position: relative;
    right: -25px;
  }

  .dashboard-split-right-section .dashboard-text2 {
    font-size: 16px;
    font-weight: bolder;
    color: var(--color-1);
  }

  .dashboard-gold-inner-card-2 .circle-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffc853;
    margin: 30px auto 0px auto;
    width: 280px;
    height: 280px;
  }
}

/* ============ MOBILE-VIEW ============ */

@media all and (max-width: 991px) {
  .dashboard-text-4 {
    font-size: 15px;
    color: var(--color-1);
    background: transparent;
    border: none;
  }

  .dashboard-gold-inner-sm-img-1 {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }

  /* ====== */
  .xcoreinner-total-grid-2 {
    background: #fff583;
    border-radius: 50%;
    width: 100px;
    margin: auto;
    margin-bottom: -175px;
    z-index: 99;
    position: relative;
    height: 100px;
  }

  .grid-dashboard-xcoreinner-l2-container {
    background: #fff583;
    border-radius: 50%;
    width: 100px;
    margin-bottom: -86px !important;
    z-index: 99;
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    margin: auto;
    font-size: 10px;
    color: #000;
    height: 100px;
  }

  .xcoreinner-total-grid-1 {
    background-color: #ffc853;
    border-radius: 50%;
    margin: auto;
    width: 200px;
    height: 200px;
  }

  .grid-dashboard-xcoreinner-l1-container {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: #ffc853;
    border-radius: 50%;
    display: grid;
    grid-template-columns: auto auto;
    margin: auto;
    gap: 4px;
    font-size: 10px;
    margin-top: 20px;
    color: #000;
    width: 200px;
    height: 200px;
  }

  .grid-dashboard-xcoreinner-l1-container .dashboard-user-xcoreinner-all-level-programs:nth-child(1) {
    grid-column: 1 / span 2;
    margin: auto;
    position: relative;
    top: -20px;
  }

  .grid-dashboard-xcoreinner-l1-container .dashboard-user-xcoreinner-all-level-programs:nth-child(2) {
    margin: auto;
    position: relative;
    top: -30px;
    left: -15px;
  }

  .grid-dashboard-xcoreinner-l1-container .dashboard-user-xcoreinner-all-level-programs:nth-child(3) {
    margin: auto;
    position: relative;
    top: -30px;
    right: -15px;
  }

  .grid-dashboard-xcoreinner-l2-container .dashboard-user-xcoreinner-all-level-2-programs:nth-child(1) {
    grid-column: 1 / span 2;
    margin: auto;
    position: relative;
    top: -25px;
  }

  .grid-dashboard-xcoreinner-l2-container .dashboard-user-xcoreinner-all-level-2-programs:nth-child(2) {
    margin: auto;
    position: relative;
    left: -18px;
  }

  .grid-dashboard-xcoreinner-l2-container .dashboard-user-xcoreinner-all-level-2-programs:nth-child(3) {
    margin: auto;
    position: relative;
    right: -18px;
  }

  .dashboard-split-right-section .dashboard-text2 {
    font-size: 16px;
    font-weight: bolder;
    color: var(--color-1);
  }

  .dashboard-gold-inner-card-2 .circle-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffc853;
    margin: 30px auto 0px auto;
    width: 200px;
    height: 200px;
  }

  /* ====== */
}


/* ================================== */
/* .xcoreinner-total-grid-overrall {
  margin-top: 100px;
}
.xcoreinner-total-grid-2 {
  background: #fff583;
  border-radius: 50%;
  width: 150px;
  margin: auto;
  margin-bottom: -250px;
  z-index: 99;
  position: relative;
  height: 150px;
}
.grid-dashboard-xcoreinner-l2-container {
  background: #fff583;
  border-radius: 50%;
  width: 150px;
  margin-bottom: -86px !important;
  z-index: 99;
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  margin: auto;
  font-size: 10px;
  color: #000;
  height: 150px;
}
.dashboard-user-xcoreinner-all-level-2-programs {
  font-size: 14px;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #c1c1c1d4;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-user-xcoreinner-programs-active {
  background-color: #fff !important;
}
.grid-dashboard-xcoreinner-l2-container .dashboard-user-xcoreinner-all-level-2-programs:nth-child(1) {
  grid-column: 1 / span 2;
  margin: auto;
  position: relative;
  top: -30px;
}
.grid-dashboard-xcoreinner-l2-container .dashboard-user-xcoreinner-all-level-2-programs:nth-child(2) {
  margin: auto;
  position: relative;
  left: -18px;
}
.grid-dashboard-xcoreinner-l2-container .dashboard-user-xcoreinner-all-level-2-programs:nth-child(3) {
  margin: auto;
  position: relative;
  right: -18px;
}
.xcoreinner-total-grid-1 {
  background-color: #ffc853;
  border-radius: 50%;
  margin: auto;
  width: 300px;
  height: 300px;
}
.grid-dashboard-xcoreinner-l1-container {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #ffc853;
  border-radius: 50%;
  display: grid;
  grid-template-columns: auto auto;
  margin: auto;
  gap: 4px;
  font-size: 10px;
  margin-top: 20px;
  color: #000;
  width: 300px;
  height: 300px;
}
.dashboard-user-xcoreinner-all-level-programs{
  font-size: 14px;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #c1c1c1d4 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.grid-dashboard-xcoreinner-l1-container .dashboard-user-xcoreinner-all-level-programs:nth-child(1) {
  grid-column: 1 / span 2;
  margin: auto;
  position: relative;
  top: -20px;
}
.grid-dashboard-xcoreinner-l1-container .dashboard-user-xcoreinner-all-level-programs:nth-child(2) {
  margin: auto;
  position: relative;
  top: -25px;
  left: -40px;
}
.grid-dashboard-xcoreinner-l1-container .dashboard-user-xcoreinner-all-level-programs:nth-child(3) {
  margin: auto;
  position: relative;
  top: -25px;
  right: -40px;
}
.grid-dashboard-xcoreinner-l1-container .dashboard-user-xcoreinner-all-level-programs:nth-child(4) {
  margin-right: auto;
  position: relative;
  top: -6px;
  left: -6px;
}
.grid-dashboard-xcoreinner-l1-container .dashboard-user-xcoreinner-all-level-programs:nth-child(5) {
  margin-left: auto;
  position: relative;
  top: -6px;
  right: -6px;
}
.grid-dashboard-xcoreinner-l1-container .dashboard-user-xcoreinner-all-level-programs:nth-child(6) {
  margin-right: auto;
  position: relative;
  top: 1px;
}
.grid-dashboard-xcoreinner-l1-container .dashboard-user-xcoreinner-all-level-programs:nth-child(7) {
  margin-left: auto;
  position: relative;
  top: 1px;
}
.grid-dashboard-xcoreinner-l1-container .dashboard-user-xcoreinner-all-level-programs:nth-child(8) {
  margin: auto;
  position: relative;
  right: -8px;
  top: 4px;
}
.grid-dashboard-xcoreinner-l1-container .dashboard-user-xcoreinner-all-level-programs:nth-child(9) {
  margin: auto;
  position: relative;
  left: -8px;
  top: 4px;
} */
/* ================================== */


/* ================================== */
.dashboard-split-right-section .recent-dusd {
  color: var(--color-1);
}

.dashboard-split-right-section .dchain-dusd {
  color: var(--color-1);
}

.dashboard-button-1:hover {
  background: var(--background-color-2);
  color: var(--background-color-1);
}

.dashboard-x3-card-1-inner-1:hover {
  /* background: linear-gradient(140deg, #ffc600 48.73%,  #ff9600 100%); */
  background: rgb(255, 136, 0);
  /* background: #ffc600; */
}

.dashboard-instructions-section .banner-section1 .card-img-top {
  width: 100%;
  height: auto;
}

.navbar-toggler:focus {
  box-shadow: none;
}

/* .dashboard-card-1 .react-tooltip{
  background-color: var(--background-color-2);
  color: var(--color-1);
  z-index: 999;
  opacity: 9;
} */
.dashboard-card-1 .react-tooltip .per-link {
  z-index: 99;
  opacity: 9;
  font-size: 12px;
  max-width: 250px;
  max-height: 100%;
  background-color: rgb(199, 152, 31);
  color: var(--color-1);
}

.dashboard-card-2 .react-tooltip .profit-link-one {
  z-index: 99;
  opacity: 9;
  font-size: 12px;
  max-width: 250px;
  max-height: 100%;
  background-color: rgb(199, 152, 31);
  color: var(--color-1);
}

.dashboard-card-2 .arrow-up {
  font-size: 12px;
}

.dashboard-split-right-section .dashboard-x3-card-2 {
  max-height: 600px;
  overflow-y: scroll;
}

.dashboard-split-right-section .card:hover {
  .arrow-right {
    color: #f0b90b;
  }
}

.dashboard-split-right-section .loader-gif {
  width: 120px;
  height: 120px;
}

.dashboard-split-right-section .spin-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.dashboard-split-right-section .spinner-border {
  width: 70px;
  height: 70px;
  /* margin-bottom: 25px; */
}

.dashboard-split-right-section .x-core-buy-btn .spinner-border {
  width: 20px;
  height: 20px;
}



/* ========================== */
/* level-income */
/* desktop */
@media (min-width:992px) {
  .level-income .member-image-top {
    position: relative;
    background-color: var(--background-color-2);
    color: var(--color-1);
    padding: 5px 60px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    border-radius: 30px;
  }

}

/* desktop */
/* Mobile */
@media (max-width:991px) {
  .level-income .member-image-top {
    position: relative;
    background-color: var(--background-color-2);
    color: var(--color-1);
    padding: 5px 40px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 12px;
    border-radius: 30px;
  }

}

/* Mobile */
.level-income .id-link {
  font-size: 20px;
}

.level-income .id-value {
  font-size: 20px;
}

.level-income .address-copy {
  color: var(--color-1);
}

.level-income .member-image-top:hover {
  background-color: #daa520;
}

.level-income .member-image:hover {
  background: var(--background-color-5);
}

/* ========================== */


/* ================================== */
/* Instruction */
/* desktop */
@media (min-width:992px) {
  .xcore-pp .inter {
    width: 250px;
    height: 250px;
  }

  .dashboard-instructions-section .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  .dashboard-instructions-section .carousel-control-next {
    right: -55px;
  }

  .dashboard-instructions-section .carousel-control-prev {
    left: -55px;
  }

  .pp-card .xcore-white {
    width: 100%;
    height: 300px;
  }

  .pp-card .xcore-dark {
    width: 100%;
    height: 300px;
  }

  .pp-card .level-white {
    width: 100%;
    height: 300px;
  }

  .pp-card .level-dark {
    width: 100%;
    height: 300px;
  }

  .pp-card .rank-white {
    width: 100%;
    height: 300px;
  }

  .pp-card .rank-dark {
    width: 100%;
    height: 300px;
  }

  .propel-market .poster-css {
    height: 300px;
    width: 100%;
    box-shadow: 0px 0px 4px 2px #5e5e5e14;
  }
}

/* desktop */
/* Mobile */
@media (max-width:991px) {
  .xcore-pp .inter {
    width: 200px;
    height: 200px;
  }

  .dashboard-instructions-section .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 2rem;
    height: 2rem;
  }

  .dashboard-instructions-section .carousel-control-next {
    right: 0px;
  }

  .dashboard-instructions-section .carousel-control-prev {
    left: 0px;
  }


  .pp-card .xcore-white {
    width: 100%;
    height: 200px;
  }

  .pp-card .xcore-dark {
    width: 100%;
    height: 200px;
  }

  .pp-card .level-white {
    width: 100%;
    height: 200px;
  }

  .pp-card .level-dark {
    width: 100%;
    height: 200px;
  }

  .pp-card .rank-white {
    width: 100%;
    height: 200px;
  }

  .pp-card .rank-dark {
    width: 100%;
    height: 200px;
  }

  .propel-market .poster-css {
    height: 250px;
    width: 100%;
    box-shadow: 0px 0px 4px 2px #5e5e5e14;
  }

}

/* Mobile */

/* tab */
@media (min-width:768px) and (max-width:991px) {
  .pp-card .xcore-white {
    width: 100%;
    height: 300px;
  }

  .pp-card .xcore-dark {
    width: 100%;
    height: 300px;
  }

  .pp-card .level-white {
    width: 100%;
    height: 300px;
  }

  .pp-card .level-dark {
    width: 100%;
    height: 300px;
  }

  .pp-card .rank-white {
    width: 100%;
    height: 300px;
  }

  .pp-card .rank-dark {
    width: 100%;
    height: 300px;
  }

  .propel-market .poster-css {
    height: 300px;
    width: 100%;
    box-shadow: 0px 0px 4px 2px #5e5e5e14;
  }
}

/* tab */

.dashboard-instructions-section .carousel-indicators {
  gap: 10px;
}

.dashboard-instructions-section .carousel-indicators [data-bs-target] {
  width: 50px;
  height: 5px;
  background-color: var(--background-color-2);
}

.dashboard-instructions-section .carousel-control-next-icon {
  filter: brightness(0.5) invert(1);
}

.dashboard-instructions-section .carousel-control-prev-icon {
  filter: brightness(0.5) invert(1);
}

.dashboard-instructions-section .carousel-indicators {
  margin-bottom: 0px;
}

.rank-income .rank-copy {
  color: var(--color-1);
}



.dark-theme .pp-card .xcore-white {
  display: none;
}

.pp-card .xcore-white {
  display: block;
}

.dark-theme .pp-card .xcore-dark {
  display: block;
}

.pp-card .xcore-dark {
  display: none;
}


.dark-theme .pp-card .level-white {
  display: none;
}

.pp-card .level-white {
  display: block;
}

.dark-theme .pp-card .level-dark {
  display: block;
}

.pp-card .level-dark {
  display: none;
}


.dark-theme .pp-card .rank-white {
  display: none;
}

.pp-card .rank-white {
  display: block;
}

.dark-theme .pp-card .rank-dark {
  display: block;
}

.pp-card .rank-dark {
  display: none;
}


.dark-theme .xcore-pp .core-white {
  display: none;
}

.xcore-pp .core-white {
  display: block;
}

.dark-theme .xcore-pp .core-dark {
  display: block;
}

.xcore-pp .core-dark {
  display: none;
}


.dark-theme .register .regis-white {
  display: none;
}

.register .regis-white {
  display: block;
}

.dark-theme .register .regis-dark {
  display: block;
}

.register .regis-dark {
  display: none;
}


.dark-theme .xcore-pp .cards-white {
  display: none;
}

.xcore-pp .cards-white {
  display: block;
}

.dark-theme .xcore-pp .cards-dark {
  display: block;
}

.xcore-pp .cards-dark {
  display: none;
}


.dark-theme .l-work .cards-white {
  display: none;
}

.l-work .cards-white {
  display: block;
}

.dark-theme .l-work .cards-dark {
  display: block;
}

.l-work .cards-dark {
  display: none;
}


.dark-theme .upgrade .cards-white {
  display: none;
}

.upgrade .cards-white {
  display: block;
}

.dark-theme .upgrade .cards-dark {
  display: block;
}

.upgrade .cards-dark {
  display: none;
}


.dark-theme .activate .tencard-white {
  display: none;
}

.activate .tencard-white {
  display: block;
}

.dark-theme .activate .tencard-dark {
  display: block;
}

.activate .tencard-dark {
  display: none;
}


.dark-theme .rankincome .ri-white {
  display: none;
}

.rankincome .ri-white {
  display: block;
}

.dark-theme .rankincome .ri-dark {
  display: block;
}

.rankincome .ri-dark {
  display: none;
}


.id-text {
  color: var(--background-color-2);
}

/* .dark-theme .level-income .level-white {
  display: none;
}

.level-income .level-white {
  display: block;
}

.dark-theme .level-income .level-dark {
  display: block;
}

.level-income .level-dark {
  display: none;
} */

.id-one {
  color: rgb(29, 127, 255);
}

.id-two {
  color: brown;
}

.id-three {
  color: violet;
}

.id-four {
  color: yellowgreen;
}

.id-five {
  color: steelblue;
}

.id-six {
  color: rgb(255, 136, 0);
}

.id-seven {
  color: darkgreen;
}

.id-eight {
  color: darkcyan;
}



.dark-theme .propel-market .poster-white {
  display: none;
}

.propel-market .poster-white {
  display: block;
}

.dark-theme .propel-market .poster-dark {
  display: block;
}

.propel-market .poster-dark {
  display: none;
}


/* Instruction */

/* ===switch card btn ===== */
.dashboard-x3-card-1 .switch-coin {
  width: 15px;
  height: 15px;
  margin: 0px 2px 2px 0px;
}

/* ===switch card btn ===== */

.yellow-colour {
  color: var(--background-color-2);
}

.bg-yellow-colour {
  background: var(--background-color-2);
}

.Dailyclaim .claim-card {
  border: 1px solid var(--background-color-5);
  border-radius: 15px;
  padding: 20px 0px;
  background-color: var(--background-color-3);
}

.Dailyclaim .card-text {
  color: var(--color-1);
  font-size: 16px;
  font-weight: 500;
}

.Dailyclaim .link-style {
  border: 1px solid var(--color-1);
  padding: 5px 10px;
  border-radius: 20px;
  color: var(--color-1);
}

.Dailyclaim .link-style-one {
  border: 1px solid var(--color-1);
  padding: 5px 10px;
  border-radius: 20px;
  color: var(--color-1);
  background-color: var(--background-color-2);
}

.Dailyclaim .link-style-one-colour {
  color: var(--color-1);
  ;
}

.Dailyclaim .tutorial-link {
  text-decoration: underline;
  color: var(--background-color-2);
  margin-left: 5px;
  font-size: 14px;
  cursor: pointer;
}

.bani-app {
  width: 20px;
  height: 28px;
}

.Dailyclaim .gobackapp {
  background-color: var(--background-color-2);
  color: var(--color-1);
  padding: 5px 25px;
  border-radius: 25px;
  border: none;
  font-size: 12px;
  font-weight: 600;
}

.Dailyclaim .baniapp-btn {
  width: 20px;
  height: 28px;
  margin-right: 5px;
}