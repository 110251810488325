@font-face {
    font-family: "GeneralSans";
    src: url("../font/GeneralSans-Medium.otf");
}

* {
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
    /* 'Bacasime Antique' */
}

:root {
    --color-1: #1e2329f5;
    --background-color-1: #ffffff;
    --background-color-2: #daa520e9;
    --background-color-3: rgb(245, 245, 245);
    --background-color-4: rgb(231, 231, 231);
    --background-color-5: rgb(226, 227, 230);
}

:root .dark-theme {
    --color-1: #ffffff;
    --background-color-1: #000210;
    --background-color-2: #daa520e9;
    --background-color-3: rgba(27, 159, 254, .021);
    --background-color-4: rgba(27, 159, 254, 0.048);
    --background-color-5: rgba(27, 159, 254, 0.1);
}

body {
    color: var(--color-1);
    /* font-family: Montserrat,sans-serif; */
    background-color: var(--background-color-1);
    font-family: "GeneralSans";
}

/* =========Desktop view =========== */
@media (min-width: 992px) {
    .home-section-one .banner-img1 {
        height: 100%;
        width: 175px;
    }

    .dark-theme .home-section-two #video-background {
        height: 100vh;
        left: 0;
        object-fit: cover;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: -1;
        background-repeat: no-repeat;
        filter: contrast(150%);
    }

    .home-section-two #video-background {
        height: 100vh;
        left: 0;
        object-fit: cover;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: -1;
        background-repeat: no-repeat;
        filter: contrast(130%);
    }

    .home-section-two {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .home-section-two .banner-section2-text1 {
        font-size: 40px;
        line-height: 50px;
        text-align: center;
        font-weight: 700;
        text-transform: capitalize;
        color: white;
        letter-spacing: 0.5px;
    }

    .home-section-two .banner-section2-text2 {
        font-size: 38px;
        line-height: 50px;
        text-align: center;
        font-weight: 700;
        text-transform: capitalize;
        color: white;
        letter-spacing: 0.5px;
    }

    .home-section-two .banner-texttwo {
        font-size: 20px;
    }

    .home-section-two .banner-btn-1 {
        background-color: var(--background-color-2);
        color: white;
        font-size: 18px;
        font-weight: 600;
        animation: buttonbannerani 2.5s infinite;
        text-shadow: 2px 2px 5px black;
    }

    .home-section-eight .banner-btn-1 {
        background-color: var(--background-color-2);
        color: white;
        font-size: 18px;
        font-weight: 600;
        animation: buttonbannerani 2.5s infinite;
        text-shadow: 2px 2px 5px black;
    }

    .influencer .influence-btn {
        background-color: var(--background-color-2);
        color: white !important;
        font-size: 18px;
        font-weight: 600;
        animation: buttonbannerani 2.5s infinite;
        text-shadow: 2px 2px 5px black;
    }

    .home-section-two .arrow-right {
        font-size: 26px;
        border-radius: 50px;
        position: relative;
        top: -1px;
        margin-left: 10px;
        margin-right: 20px;
        background-color: #ffffff;
        -webkit-animation-duration: 2.5s;
        animation-duration: 2.5s;
        -webkit-animation-name: moveanimation;
        animation-name: moveanimation;
        animation-iteration-count: infinite;
        opacity: 0.8;
        color: #000;
    }

    .home-section-eight .arrow-right {
        font-size: 26px;
        border-radius: 50px;
        position: relative;
        top: -1px;
        margin-left: 10px;
        margin-right: 20px;
        background-color: #ffffff;
        -webkit-animation-duration: 2.5s;
        animation-duration: 2.5s;
        -webkit-animation-name: moveanimation;
        animation-name: moveanimation;
        animation-iteration-count: infinite;
        opacity: 0.8;
        color: #000;
    }

    .influencer .influence-btn-icon {
        font-size: 26px;
        border-radius: 50px;
        position: relative;
        top: -1px;
        margin-left: 10px;
        margin-right: 20px;
        background-color: #ffffff;
        -webkit-animation-duration: 2.5s;
        animation-duration: 2.5s;
        -webkit-animation-name: moveanimation;
        animation-name: moveanimation;
        animation-iteration-count: infinite;
        opacity: 0.8;
        color: #000;
    }

    .home-section-three .banner-text {
        color: var(--background-color-2);
        font-size: 3rem;
    }

    .home-section-three .banner-section-3 {
        padding-bottom: 100px;
        padding-top: 100px;
    }

    .home-section-three .banner-btn-3 {
        background-color: #406aff1a;
        border: none;
        border-radius: 20px;
        color: #406aff !important;
        padding: 5px 10px;
        font-size: 16px;
    }

    .home-section-three .textvalue {
        color: white;
        font-size: 16px;
    }

    .home-section-three .banner-img-4 {
        height: 30px;
        width: 30px;
    }

    .home-section-four .banner-section-4 {
        padding-bottom: 100px;
        padding-top: 100px;
    }

    .home-section-four .banner-section4-text1 {
        font-size: 38px;
        color: var(--background-color-2);
    }

    .home-section-four .banner-section4-text2 {
        font-size: 16px;
        font-weight: 300;
    }

    .home-section-five {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .home-section-five .banner-text {
        font-size: 38px;
        color: var(--background-color-2);
    }

    .home-section-five .card-icon {
        width: 70px;
        height: 70px;
    }

    .home-section-six {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .home-section-six .btn__bordered_gradient {
        align-items: center;
        border-radius: 50%;
        display: flex;
        height: 70px;
        justify-content: center;
        margin: auto;
        position: relative;
        width: 70px;
        font-size: 35px;
        background: var(--background-color-5);
    }

    .home-section-seven {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .home-section-seven .banner-section7-text1 {
        font-weight: 500;
        font-size: 24px !important;
        line-height: 28px;
        padding: 20px;
    }

    .home-section-seven .accordion-button::after {
        width: 30px;
        height: 30px;
        background-size: 30px;
        /* background-image: url(http://localhost:3001/static/media/arrowdown.28bb408….png)!important; */
    }

    .home-section-seven .banner-section7-text2 {
        font-weight: 300;
        font-size: 18px !important;
        line-height: 28px !important;
        padding-right: 20px !important;
    }

    .home-section-seven .pen-img {
        width: 20px;
        height: 20px;
        margin-right: 30px;
    }

    .home-section-seven .q-tag {
        width: 280px;
        height: auto;
        filter: blur(1px) contrast(0.1);
        position: absolute;
        right: 5%;
        top: 10px;
        opacity: 0.3;
    }

    .home-section-eight {
        padding-top: 50px;
        padding-bottom: 100px;
    }

    .home-section-eight .info-button {
        padding: 5px 15px;
        background: var(--background-color-2);
        color: white;
        border-radius: 10px;
        font-size: 14px;
        border: 1px solid rgba(27, 159, 254, 0.1);
    }

    .home-section-eight .text-two {
        font-size: 14px;
    }

    .home-section-eight .tutorial-link {
        text-decoration: underline;
        color: #0d6efd !important;
        margin-left: 5px;
        font-size: 14px;
        cursor: pointer;
    }

    .home-section-eight .card {
        height: 200px;
    }

    .home-section-eight .video-card {
        width: 100%;
        height: 350px;
        box-shadow: 0px 0px 4px 2px #dfdfdf14;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
    }
    .home-section-two .baniapp{
        width: 28px;
        height: 42px;
        margin-right: 5px;
        vertical-align: sub;
    }
}

/* =========Desktop view End=========== */

/* =========Mobile view =========== */
@media (max-width: 992px) {
    .home-section-one {
        background: var(--background-color-1);
    }

    .home-section-one .banner-img1 {
        height: 100%;
        width: 115px;
    }

    .dark-theme .home-section-two #video-background {
        height: 100vh;
        left: 0;
        object-fit: cover;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: -1;
        background-repeat: no-repeat;
        filter: contrast(150%);
    }

    .home-section-two #video-background {
        height: 100vh;
        left: 0;
        object-fit: cover;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: -1;
        background-repeat: no-repeat;
        filter: contrast(135%);
    }

    .home-section-two {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .home-section-two .banner-section2-text1 {
        font-size: 25px;
        line-height: 35px;
        text-align: center;
        font-weight: 700;
        text-transform: capitalize;
        color: white;
    }

    .home-section-two .banner-section2-text2 {
        font-size: 24px;
        line-height: 35px;
        text-align: center;
        font-weight: 700;
        text-transform: capitalize;
        color: white;
    }

    .home-section-two .banner-texttwo {
        font-size: 14px;
    }

    .home-section-two .banner-btn-1 {
        background-color: var(--background-color-2);
        color: white;
        font-size: 14px;
        font-weight: 600;
        animation: buttonbannerani 2.5s infinite;
        text-shadow: 2px 2px 5px black;
    }

    .home-section-eight .banner-btn-1 {
        background-color: var(--background-color-2);
        color: white;
        font-size: 14px;
        font-weight: 600;
        animation: buttonbannerani 2.5s infinite;
        text-shadow: 2px 2px 5px black;
    }

    .influencer .influence-btn {
        background-color: var(--background-color-2);
        color: white !important;
        font-size: 14px;
        font-weight: 600;
        animation: buttonbannerani 2.5s infinite;
        text-shadow: 2px 2px 5px black;
    }

    .home-section-two .arrow-right {
        font-size: 16px;
        border-radius: 50px;
        position: relative;
        top: -1px;
        margin-left: 10px;
        margin-right: 20px;
        background-color: #ffffff;
        -webkit-animation-duration: 2.5s;
        animation-duration: 2.5s;
        -webkit-animation-name: moveanimation;
        animation-name: moveanimation;
        animation-iteration-count: infinite;
        opacity: 0.8;
        color: #000;
    }

    .home-section-eight .arrow-right {
        font-size: 16px;
        border-radius: 50px;
        position: relative;
        top: -1px;
        margin-left: 10px;
        margin-right: 20px;
        background-color: #ffffff;
        -webkit-animation-duration: 2.5s;
        animation-duration: 2.5s;
        -webkit-animation-name: moveanimation;
        animation-name: moveanimation;
        animation-iteration-count: infinite;
        opacity: 0.8;
        color: #000;
    }

    .home-section-three .banner-text {
        color: var(--background-color-2);
        font-size: 2rem;
    }

    .home-section-three .banner-section-3 {
        padding-bottom: 50px;
        padding-top: 50px;
    }

    .home-section-three .banner-btn-3 {
        background-color: #406aff1a;
        border: none;
        border-radius: 20px;
        color: #406aff !important;
        padding: 5px 10px;
        font-size: 12px;
    }

    .home-section-three .textvalue {
        color: white;
        font-size: 12px;
    }

    .home-section-three .banner-img-4 {
        height: 25px;
        width: 25px;
    }

    .home-section-four .banner-section-4 {
        padding-bottom: 50px;
        padding-top: 50px;
    }

    .home-section-four .banner-section4-text1 {
        font-size: 30px;
        color: var(--background-color-2);
    }

    .home-section-four .banner-section4-text2 {
        font-size: 12px;
        font-weight: 300;
    }

    .home-section-five {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .home-section-five .banner-text {
        font-size: 30px;
        color: var(--background-color-2);
    }

    .home-section-five .card-icon {
        width: 50px;
        height: 50px;
    }

    .home-section-six {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .home-section-six .btn__bordered_gradient {
        align-items: center;
        border-radius: 50%;
        display: flex;
        height: 50px;
        justify-content: center;
        margin: auto;
        position: relative;
        width: 50px;
        font-size: 25px;
        background: var(--background-color-5);
    }

    .home-section-seven .banner-section7-text1 {
        font-weight: 500;
        font-size: 12px !important;
        padding: 8px;
    }

    .home-section-seven .accordion-button::after {
        width: 20px;
        height: 20px;
        background-size: 20px;
        /* background-image: url(http://localhost:3001/static/media/arrowdown.28bb408….png)!important; */
    }

    .home-section-seven .banner-section7-text2 {
        font-weight: 300;
        font-size: 12px !important;
        padding-right: 20px !important;
    }

    .home-section-seven .pen-img {
        width: 15px;
        height: 15px;
        margin-right: 5px;
    }

    .home-section-seven .q-tag {
        display: none;
    }

    .home-section-eight {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .home-section-eight .info-button {
        padding: 5px;
        background: var(--background-color-2);
        color: white;
        border-radius: 10px;
        font-size: 14px;
        border: 1px solid rgba(27, 159, 254, 0.1);
    }

    .home-section-eight .text-two {
        font-size: 13px;
    }

    .home-section-eight .tutorial-link {
        text-decoration: underline;
        color: #0d6efd !important;
        margin-left: 5px;
        font-size: 13px;
        cursor: pointer;
    }

    .home-section-eight .card {
        height: auto;
    }

    .home-section-eight .video-card {
        width: 100%;
        height: 200px;
        box-shadow: 0px 0px 4px 2px #5e5e5e14;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
    }
}

/* =========Mobile view End=========== */

/* =========Home Start ================ */
/* .dark-theme .home-section-one{
    background: transparent;
}
.home-section-one{
    background: transparent;
} */
.home-section-one .navbar .btn {
    background: var(--background-color-5);
    color: var(--color-1);
    border-radius: 16px;
    font-size: 14px;
}

.home-section-one .navbar .btn:hover {
    background-color: var(--background-color-2);
}

.dmlm-home-section .navbar-toggler-icon {
    filter: brightness(0) invert(1);
}

.dmlm-home-section .navbar-toggler {
    border: 1px solid var(--color-1);
    filter: brightness(0.5) invert(0.5);
}

.home-section-one .navbar-toggler:focus {
    box-shadow: none;
}

.home-section-two .gradient-color {
    color: var(--background-color-2);
}

.home-section-two .banner-btn-1:hover {
    background-color: #000210;
    color: var(--background-color-2);
}

.home-section-eight .banner-btn-1:hover {
    background-color: #000210;
    color: var(--background-color-2);
}

.influencer .influence-btn:hover {
    background-color: #000210;
    color: var(--background-color-2);
    border-color: transparent;
}

@keyframes buttonbannerani {
    0% {
        box-shadow: 0 0 0 0 #33eacc49;
        -webkit-transform: scale(.95);
        transform: scale(.95);
    }

    50% {
        box-shadow: 0 0 0 10px transparent;
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    100% {
        box-shadow: 0 0 0 0 #33eacc49;
        -webkit-transform: scale(.95);
        transform: scale(.95);
    }
}


.letter-animation span {
    display: inline-block;
    opacity: 0;
    animation: fadeIn 1s forwards;
    text-transform: none;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateX(50px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.letter-animation span:nth-child(1) {
    animation-delay: 0.1s;
}

.letter-animation span:nth-child(2) {
    animation-delay: 0.2s;
}

.letter-animation span:nth-child(3) {
    animation-delay: 0.3s;
}

.letter-animation span:nth-child(4) {
    animation-delay: 0.4s;
}

.letter-animation span:nth-child(5) {
    animation-delay: 0.5s;
}

.letter-animation span:nth-child(6) {
    animation-delay: 0.6s;
}

.letter-animation span:nth-child(7) {
    animation-delay: 0.7s;
}


.home-section-eight .row-one {}

.home-section-eight .row-one .card {
    /* From https://css.glass */
    background: rgba(27, 159, 254, .021);
    border-radius: 16px;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid #ffffff0a;
    box-shadow: 0px 0px 3px 1px rgba(27, 159, 254, 0.1);
}

.dark-theme .home-section-eight .row-one .card {
    /* From https://css.glass */
    background: rgba(27, 159, 254, .021);
    border-radius: 16px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid #ffffff0a;
    box-shadow: 0px 0px 3px 1px rgba(27, 159, 254, 0.1);
}

.home-section-eight .row-one .card:hover {
    background: rgba(27, 159, 254, 0.048);
}

.home-section-eight .row-one .card .card-title {
    color: var(--background-color-2);
    font-weight: 600;
}

.home-section-eight .row-one .info-steps {
    font-size: 13px;
    line-height: 1.5;
    padding-left: 1rem;
}

.home-section-eight .info-button:hover {
    background: rgba(27, 159, 254, 0.1);
}

.home-section-eight .video-card:hover {
    box-shadow: 0px 0px 8px 5px #dfdfdf14;
}






.home-section-three {
    /* background-image: linear-gradient(108.46deg,#2A6295 12.51%,#494990 87.49%); */
    background-image: linear-gradient(345deg, rgb(0, 67, 104) 3.6%, rgb(8 29 68) 87.6%);
}

/* .home-section-three{
    background:rgb(226,227,230);
    background-image: linear-gradient(to top, #e9f4fb 0%, #e2ebf0 100%);
} */
@keyframes moveanimation {
    0% {
        margin-left: 10px;
        margin-right: 20px;
    }

    50% {
        margin-right: 10px;
        margin-left: 20px;
    }

    100% {
        margin-left: 10px;
        margin-right: 20px;
    }
}

.home-section-three .see-more-users-box {
    background: #000e27;
    box-shadow: inset 2px 2px 5px 2px #cecece09;
    max-height: 800px;
    overflow-y: scroll;
    border-radius: 15px;
    /* scrollbar-color:#ffffff1a #000e27; */
}

.home-section-three .see-more-users-box::-webkit-scrollbar {
    border-radius: 50px;
    opacity: 0.5;
    z-index: 0;
}

.home-section-three .see-more-users-box::-webkit-scrollbar-track {
    background-color: rgba(6, 32, 62, 0.226) !important;
    border-radius: 0px 50px 50px 0px;
}

.home-section-three .see-more-users-box::-webkit-scrollbar-thumb {
    /* background-color: rgb(2, 10, 28) !important; */
    border-radius: 0px 50px 50px 0px;
}

.home-section-three .banner-border-bottom-3 {
    border-bottom: 1px solid #3a3b3c47;
}

.home-section-three .banner-icon-3 {
    background-color: #2cff4e33 !important;
    color: #2cff4e !important;
}

.home-section-three .banner-section-3 .btn-see {
    background-color: #011431;
    color: white;
    border: 0;
    outline: none;
    border-radius: 15px;
    letter-spacing: 1px;
    font-size: 18px;
    box-shadow: inset 0 0 4px 1px #80808026;
    width: 100%;
}

.home-section-three .xcore-text {
    color: var(--background-color-2);
}

.home-section-three .banner-section-3 .btn-see:hover {
    background-color: #031D3D;
}

.home-section-three .banner-btn-3:hover {
    background-color: #4069ff73;
}

.home-section-four .text-two {
    color: var(--color-1);
}

.home-section-five .text-two {
    color: var(--color-1);
}

.home-section-four {
    background: var(--background-color-1);
}

.home-section-four .banner-section4-btn {
    color: var(--color-1);
    background-color: var(--background-color-2);
    /* border: 0.4em solid var(--background-color-5); */
    border-radius: 40px;
    font-weight: 700;
    border: none;
}

.home-section-four .banner-section4-num1 {
    font-size: 42px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 5px;
}

.home-section-four .dusd-add {
    color: var(--background-color-2);
}

.home-section-five {
    background: var(--background-color-1);
}

.home-section-five .card {
    border: 1px solid hsla(0, 2%, 90%, .034);
    border-radius: 20px;
    box-shadow: 0 20px 40px 0 hsla(0, 100%, 97%, .02);
    -webkit-backdrop-filter: blur(55px);
    backdrop-filter: blur(55px);
    background: var(--background-color-3);
    color: var(--color-1);
    padding: 22px;
}

/* .home-section-five .card:hover{
    .card-icon{
        filter: drop-shadow(0px 0px 2px var(--color-1));
    }
} */
.home-section-five .card:hover {
    background: var(--background-color-4);
}

.home-section-five .card:hover h4 {
    color: var(--background-color-2) !important;
}

.home-section-five .card:hover p {
    color: var(--color-1) !important;
    opacity: 1 !important;
}

.dark-theme .home-section-six {
    background: var(--background-color-1);
}

.home-section-six {
    background: var(--background-color-3);
}

.home-section-six .banner-text {
    color: var(--background-color-2);
}

.home-section-six .btn__bordered_gradient:before {
    border-radius: 100% !important;
    bottom: 0;
    content: "";
    left: 0;
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    padding: 2px;
    position: absolute;
    right: 0;
    top: 0;
    border: 3px solid var(--background-color-5);
}

.home-section-six .btn__bordered_gradient:hover {
    background: var(--background-color-2);
}

.home-section-six .banner-section9-text {
    align-items: center;
    color: var(--color-1);
    display: flex;
    justify-content: center;
    margin: auto;
    max-width: 90px !important;
    padding-top: 20px;
    position: relative;
    text-align: center;
    font-size: 16px;
}



.homenav {
    background: var(--background-color-1);
}

.home-section-seven {
    background: var(--background-color-1);
}

.home-section-seven .banner-text-3 {
    color: var(--background-color-2);
}

.home-section-seven .accordion-item {
    color: var(--color-1) !important;
    background-color: var(--background-color-3) !important;
    border: 1px solid var(--background-color-5) !important;
    margin: 10px;
    border-radius: 20px;
}

.home-section-seven .accordion-button {
    color: var(--color-1) !important;
    background-color: transparent !important;
}

.home-section-seven .accordion-button:focus {
    box-shadow: none;
}

.home-section-seven .accordion-button:not(.collapsed) {
    box-shadow: none;
}

/* .home-section-seven .accordion-item:hover{
    background-color:var(--background-color-5) !important;
} */

.home-section-seven .accordion-item:focus-within {
    background-color: var(--background-color-4) !important;
}

.home-section-seven .accordion-item:hover {
    .accordion-button::after {
        filter: drop-shadow(0px 0px 0px var(--background-color-2));
    }
}

.influencer .influence-btn {
    --bs-btn-border-color: transparent;
}

/* =========Home End ================ */

/* ===========Footer start ============ */
.dark-theme .footer-section {
    background: rgb(2, 10, 28);
}

.footer-section {
    background: rgb(245, 245, 245);
}

.footer-section .footer-icon {
    padding: 10px;
    font-size: 40px;
    border: none;
    background: var(--background-color-5);
    color: var(--color-1);
    border-radius: 50%;
}

.footer-section .footer-icon:hover {
    background: var(--background-color-4);
}



.dark-theme .footer-section .modal-content {
    background: rgb(2, 10, 28);
    color: var(--color-1)
}

.footer-section .modal-content {
    background: rgb(245, 245, 245);
    color: var(--color-1)
}

.footer-section .modal-content {
    border: none !important;
    border-radius: 10px;
}

.footer-section .modal-content .btn-close {
    filter: brightness(0.5) invert(0.5);
}

.footer-section .ido-active-text-2 {
    color: var(--color-1);
    font-size: 16px;
    letter-spacing: -.02em;
    line-height: 180%;
    width: 70%;
}

.footer-section .form-control {
    -webkit-appearance: none;
    appearance: none;
    background-clip: padding-box;
    background-color: transparent !important;
    border: 1px solid grey;
    border-radius: 0.375rem !important;
    box-shadow: none !important;
    color: var(--color-1) !important;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
}

.footer-section .banner-top-button-copy {
    border: none;
    border-radius: 2em;
    font-size: 16px;
    padding: 10px 40px;
    background: #0478e9;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    position: relative;
    text-align: center;
    text-decoration: none;
}

.footer-section .banner-top-button-copy:hover {
    background: var(--background-color-2);
}



.footer-section .influence-btn {
    border: none;
    border-radius: 2em;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 25px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: .4s;
    background: var(--background-color-2);
    color: var(--color-1);
    /* text-shadow: 2px 2px 5px var(--background-color-1); */
    transition: all 0.6s ease;
}





.footer-section .influence-btn {
    position: relative;
    overflow: hidden;
    background: var(--background-color-2);
    transition: 0.5s;
    width: 200px;
    height: 40px;
}

.footer-section .influence-btn div {
    position: absolute;
    top: 0.4em;
    left: 1.15em;
    margin: 0;
    padding: 0;
    transition: .5s;
    color: black;
}

.footer-section .influence-btn .influence-btn-icon {
    position: absolute;
    top: 0.80em;
    right: 0.5em;
    margin: 0;
    padding: 0;
    opacity: 0;
    transition: 0.5s;
    height: 1em;
    fill: var(--color-1)
}

.footer-section .influence-btn:hover div {
    left: 0.5em;
    color: var(--color-1);
}

.footer-section .influence-btn:hover .influence-btn-icon {
    opacity: 1;
}

.footer-section .influence-btn:hover {
    background-color: var(--background-color-5);
}

.dark-theme .footer-section .influence-btn:hover {
    background-color: rgb(3, 18, 40);
}










/* #affiliateinfluencers .css-b62m3t-container {
    right: 0;
    width: 180px;
} */
#affiliateinfluencers .css-1dimb5e-singleValue {
    color: var(--color-1);
}

#affiliateinfluencers .css-1dimb5e-singleValue {
    width: 100px;
}

#affiliateinfluencers .PhoneInputCountry {
    margin-left: 0.3em;
}

#affiliateinfluencers input::placeholder {
    color: var(--color-1);
    /* Placeholder text color */
    font-size: 12px;
    /* Placeholder font size */
    opacity: 0.5;
    /* Opacity (1 is fully opaque, 0 is fully transparent) */
}

#affiliateinfluencers .PhoneInputInput {
    border-radius: 10px;
    padding: 5px;
    color: var(--color-1);
    background-color: var(--background-color-4);
    border: none;
}


#affiliateinfluencers .custom-placeholder::-webkit-input-placeholder {
    font-size: 13px;
    /* Change the font size */
    color: var(--color-1);
    /* Change the color */
}

#affiliateinfluencers .custom-placeholder:-moz-placeholder {
    font-size: 13px;
    color: var(--color-1);
}

#affiliateinfluencers.custom-placeholder::-moz-placeholder {
    font-size: 13px;
    color: var(--color-1);
}

#affiliateinfluencers .custom-placeholder:-ms-input-placeholder {
    font-size: 13px;
    color: var(--color-1);
}


/* Ensure the input field itself is transparent */
.footer-section .form-control {
    background-color: transparent !important;
}

/* Autocomplete dropdown styling */
.footer-section .ui-autocomplete {
    background-color: transparent !important;
    border: none;
    box-shadow: none;
}

/* Autocomplete item styling */
.footer-section .ui-menu .ui-menu-item {
    background: transparent !important;
    border: none;
    color: #000;
    /* Adjust as needed */
}

/* Autocomplete item hover styling */
.footer-section .ui-menu .ui-menu-item:hover {
    background: transparent;
    /* Light transparent background on hover */
    color: #000;
    /* Adjust as needed */
}

/* Ensure focused input retains transparency */
.footer-section .form-control:focus {
    background-color: transparent !important;
    border-color: #5cb3fd;
    /* Adjust as needed */
    outline: none;
    box-shadow: none;
}


.footer-section input::-webkit-outer-spin-button,
.footer-section input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.footer-section input[type=number] {
    -moz-appearance: textfield;
}


.submit .modal-body {
    background-color: rgb(255 255 255);
}

.dark-theme .submit .modal-body {
    background-color: rgb(3, 18, 40);
}

.submit .modal-content {
    background-color: transparent;
    border: none;
}

.submit .modal-body {
    border-radius: 20px;
}

.submit .banner-img1 {
    height: 100%;
    width: 100px;
}

.dark-theme .submit .white-logo {
    display: none;
}

.submit .white-logo {
    display: block;
}

.dark-theme .submit .black-logo {
    display: block;
}

.submit .black-logo {
    display: none;
}

/* .submit .modal{
    --bs-modal-width: 600px;
  }
  .submit .modal-body{
    height: 300px;
  } */
.submit .submit-icon {
    font-size: 30px;
    border: 1px solid var(--background-color-5);
    border-radius: 50%;
    padding: 5px;
    background: var(--background-color-6);
}

.submit .submit-icon:hover {
    background: var(--background-color-2);
}

.submit .pop-btn-one {
    padding: 5px 20px;
    background: var(--background-color-2);
    color: var(--color-1);
    border: none;
    border-radius: 10px;
}

.submit .pop-three-one {
    font-size: 30px;
    font-weight: 500;
}


.dark-theme #affiliateinfluencers .modal-content {
    background: rgb(2, 10, 28);
    color: var(--color-1)
}

#affiliateinfluencers .modal-content {
    background: rgb(245, 245, 245);
    color: var(--color-1)
}

#affiliateinfluencers .modal-content .btn-close {
    filter: brightness(0.5) invert(0.5);
}

#affiliateinfluencers .ido-active-text-2 {
    color: var(--color-1);
    font-size: 16px;
    letter-spacing: -.02em;
    line-height: 180%;
    width: 70%;
}

#affiliateinfluencers .form-control {
    background-color: transparent !important;
}

#affiliateinfluencers .form-control {
    appearance: none;
    background-clip: padding-box;
    background-color: transparent !important;
    border: 1px solid grey;
    border-radius: 0.375rem !important;
    box-shadow: none !important;
    color: var(--color-1) !important;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
}

#affiliateinfluencers .banner-top-button-copy {
    border: none;
    border-radius: 2em;
    font-size: 16px;
    padding: 10px 40px;
    background: #0478e9;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    position: relative;
    text-align: center;
    text-decoration: none;
}

#affiliateinfluencers input::-webkit-outer-spin-button,
#affiliateinfluencers input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
#affiliateinfluencers input[type=number] {
    -moz-appearance: textfield;
}

/* ===========Footer End ============ */


/* Home logo */
@media (min-width:992px) {
    .dark-theme .white-logo {
        display: none;
    }

    .dark-theme .black-logo {
        display: block;
    }

    .dark-theme .homenav .white-logo {
        display: none;
    }

    .dark-theme .homenav .black-logo {
        display: block;
    }

    .homenav .black-logo {
        display: none;
    }

    .homenav .white-logo {
        display: block;
    }

    .white-logo {
        display: none;
    }

}

@media (max-width:992px) {
    .dark-theme .white-logo {
        display: none;
    }

    .dark-theme .black-logo {
        display: block;
    }

    .black-logo {
        display: none;
    }
}




/* login logo */
.dark-theme .login-section-one .white-logo {
    display: none;
}

.dark-theme .login-section-one .black-logo {
    display: block;
}

.login-section-one .black-logo {
    display: none;
}

.login-section-one .white-logo {
    display: block;
}


/* register logo */
.dark-theme .register-section-one .white-logo {
    display: none;
}

.dark-theme .register-section-one .black-logo {
    display: block;
}

.register-section-one .black-logo {
    display: none;
}

.register-section-one .white-logo {
    display: block;
}


/* dashboard logo */
.dark-theme .dashboard-navbar-section .white-logo {
    display: none;
}

.dark-theme .dashboard-navbar-section .black-logo {
    display: block;
}

.dashboard-navbar-section .black-logo {
    display: none;
}

.dashboard-navbar-section .white-logo {
    display: block;
}




/* ============= */
.modal-popup .modal-body {
    background-color: rgb(203, 203, 203);
}

.dark-theme .modal-popup .modal-body {
    background-color: rgb(3, 18, 40);
}

.modal-popup .modal-content {
    background-color: transparent;
    border: none;
}

.modal-popup .modal-body {
    border-radius: 20px;
}

.modal-popup .pop-btn {
    padding: 5px 20px;
    background: var(--background-color-2);
    color: var(--color-1);
    border: none;
    border-radius: 10px;
}

@media (min-width: 992px) {
    .modal-popup .pop-three {
        font-size: 16px;
        font-weight: 500;
    }
}

@media (max-width: 991px) {
    .modal-popup .pop-three {
        font-size: 14px;
        font-weight: 500;
    }
}

/* ============= */


/* =======information start ============ */
/* ====Desktop View ======= */
@media (min-width: 992px) {
    .instructioninfo .dashboard-split-right-section {
        padding-top: 0px;
        padding-bottom: 40px;
    }

    .CalculatorInfo .dashboard-split-right-section {
        padding-top: 0px;
        padding-bottom: 40px;
    }
}

/* ====Desktop View End ======= */
/* ====Mobile view ========== */
@media (max-width: 991px) {
    .instructioninfo .dashboard-split-right-section {
        padding-top: 20px;
        padding-bottom: 40px;
    }

    .CalculatorInfo .dashboard-split-right-section {
        padding-top: 20px;
        padding-bottom: 40px;
    }
    .home-section-two .baniapp{
        width: 28px;
        height: 42px;
        margin-right: 5px;
        vertical-align: middle;
    }
}

/* ====Mobile view End========== */
.navbarinfo .navbar .container {
    background-color: transparent;
    padding: 4px;
    border-radius: 14px;
}

/* =======information start ============ */


.unselectable {
    /* Prevent text selection */
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    /* Prevent cropping */
    overflow: visible;

    /* Disable pointer interaction */
    pointer-events: none;

    /* Hide element from being easily inspected */
    position: relative;
}

.unselectable::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    z-index: 1000;
    /* Ensure it's overlaid on top */
    pointer-events: none;
    /* Disables interaction */
}

.home-section-two .bani-text {
    color: rgb(80,207,215);
}

.home-section-two .blink-soft {
    animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0.1;
    }
}