/* @import url("../font/GeneralSans-Medium.otf"); */

@font-face {
  font-family: "GeneralSans";
  src: url("../font/GeneralSans-Medium.otf");
}

* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  /* 'Bacasime Antique' */
}

:root {
  --color-1: #1e2329f5;
  --background-color-1: #ffffff;
  --background-color-2: #daa520e9;
  --background-color-3: rgb(245, 245, 245);
  --background-color-4: rgb(231, 231, 231);
  --background-color-5: rgb(226, 227, 230);
  --background-color-6: rgb(245, 245, 245);
}

:root .dark-theme {
  --color-1: #ffffff;
  --background-color-1: #000210;
  --background-color-2: #daa520e9;
  --background-color-3: rgba(27, 159, 254, 0.021);
  --background-color-4: rgba(27, 159, 254, 0.048);
  --background-color-5: rgba(27, 159, 254, 0.1);
  --background-color-6: rgba(27, 159, 254, 0.1);
}

body {
  color: var(--color-1);
  /* font-family: Montserrat,sans-serif; */
  background-color: var(--background-color-1);
  font-family: "GeneralSans";
}
/* =========Desktop view =========== */
@media (min-width: 992px) {
  .login-section-one .navbar-brand img {
    height: 100%;
    width: 175px;
  }
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .register-section-two .registerationchild01 {
    line-height: 70px;
  }
  .d-ecosystem-login .login-section-one .button1 {
    padding: 11px 20px;
    border-radius: 20px;
    border: 0;
    outline: none;
    font-size: 15px;
    color: var(--color-1);
    background-color: var(--background-color-5);
    font-weight: 600;
  }
  .d-ecosystem-login .login-section-one .button1:hover {
    background-color: var(--background-color-2);
  }
  .d-ecosystem-login .login-section-two {
    padding-top: 150px;
    padding-bottom: 50px;
  }
  .login-section-two .secondpartchild {
    padding: 25px;
    border-radius: 20px;
  }
  .login-section-two h4 {
    font-size: 38px;
    color: var(--color-1);
    text-transform: capitalize;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .login-section-four .fourthbannericontags {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: rgba(44, 255, 78, 0.2);
    color: #2bea4a;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login-section-four .fourthbannerbtn1 {
    padding: 4px 6px;
    color: var(--background-color-2);
    background-color: #ffd7002e;
    border-radius: 15px;
    font-size: 16px;
  }
  .login-section-four .btn21 {
    color: var(--color-1);
    font-size: 16px;
  }
  .login-section-four .fourthbanner13 {
    color: var(--color-1);
    font-size: 16px;
  }
  .register-section-two {
    padding-top: 100px;
  }
  .register-section-one .navbar-brand img {
    height: 100%;
    width: 175px;
  }
  .login-section-three .text-two {
    font-size: 14px;
  }
  .login-section-three .tutorial-link {
    text-decoration: underline;
    color: #0d6efd !important;
    margin-left: 5px;
    font-size: 14px;
    cursor: pointer;
  }
  .register-section-two .text-two {
    font-size: 14px;
  }
  .register-section-two .tutorial-link {
    text-decoration: underline;
    color: #0d6efd !important;
    margin-left: 5px;
    font-size: 14px;
    cursor: pointer;
  }
}
/* =========Desktop view =========== */

/* =========Mobile View ==========*/
@media (max-width: 992px) {
  .login-section-one .navbar-brand img {
    height: 100%;
    width: 120px;
  }
  .d-ecosystem-login .login-section-two {
    padding-top: 100px;
  }
  .login-section-three .thirdpartchild02 .hiddenmobile {
    display: none;
  }
  .d-ecosystem-login .login-section-one .button1 {
    padding: 11px 20px;
    border-radius: 20px;
    border: 0;
    outline: none;
    font-size: 12px;
    color: var(--color-1);
    background-color: var(--background-color-5);
    font-weight: 600;
    width: max-content;
  }
  .login-section-two .secondpartchild {
    padding: 10px;
    border-radius: 20px;
  }
  .login-section-two h4 {
    font-size: 30px;
    color: var(--color-1);
    text-transform: capitalize;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .login-section-four .fourthbannericontags {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background-color: rgba(44, 255, 78, 0.2);
    color: #2bea4a;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login-section-four .fourthbannerbtn1 {
    padding: 4px 6px;
    color: var(--background-color-2);
    background-color: #ffd7002e;
    border-radius: 15px;
    font-size: 14px;
  }
  .login-section-four .btn21 {
    color: var(--color-1);
    font-size: 14px;
  }
  .login-section-four .fourthbanner13 {
    color: var(--color-1);
    font-size: 12px;
  }
  .register-section-two {
    padding-top: 50px;
  }
  .register-section-one .navbar-brand img {
    height: 100%;
    width: 120px;
  }
  .login-section-three .text-two {
    font-size: 13px;
  }
  .login-section-three .tutorial-link {
    text-decoration: underline;
    color: #0d6efd !important;
    margin-left: 5px;
    font-size: 13px;
    cursor: pointer;
  }
  .register-section-two .text-two {
    font-size: 13px;
  }
  .register-section-two .tutorial-link {
    text-decoration: underline;
    color: #0d6efd !important;
    margin-left: 5px;
    font-size: 13px;
    cursor: pointer;
  }
}
/* =========Mobile View ==========*/

/* ==========Home start============ */
/* .home-section-one .banner-icon1.navbar-toggler-icon {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeAgMAAABGXkYxAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAlQTFRFAAAA////////c3ilYwAAAAN0Uk5TAI+/HbPuRwAAABVJREFUeJxjYCALMK4CgSVIjGFhDgCNtxfLzgFueAAAAABJRU5ErkJggg==) !important;
} */
/* .home-section-one{
    background: var(--background-color-3)
} */
.home-section-one .navbar .btn {
  background: var(--background-color-5);
  color: var(--color-1);
  border-radius: 16px;
  font-size: 14px;
}
.home-section-one .navbar .btn:hover {
  background-color: var(--background-color-2);
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  border: none;
}
.dark-theme .navbar-toggler-icon {
  filter: brightness(0) invert(1);
}
.dark-theme .navbar-toggler {
  border: 1px solid var(--color-1);
  filter: brightness(0.5) invert(0.5);
}
/* ==========Home End============ */

/* =========Login Start =============== */
.dark-theme .login-section-one {
  background: var(--background-color-1);
}
.login-section-one {
  background: var(--background-color-3);
}
.login-section-one .button1:hover {
  background-color: var(--background-color-5);
}
.login-section-two .secondpartparent {
  border-radius: 20px;
  background-color: var(--background-color-2);
  padding: 20px 0px;
  margin: 0px;
}
.login-section-two .banner-img {
  width: 450px;
  height: 300px;
  position: absolute;
  top: 125px;
}
.login-section-two .secondpartbtn1:hover {
  background-color: #424649;
}
.login-section-two .secondpartbtn2 {
  background: #ffca2c;
}
.login-section-two .secondpartbtn2:hover {
  background: #ffc107;
}
.login-section-three .thirdpartchild0 {
  background-color: #daa520b5;
  border-radius: 15px;
  padding: 30px 20px;
}
.login-section-three .thirdpartchild0 h5 {
  color: var(--color-1);
  font-size: 16px;
}
.login-section-three .thirdpartchild0 input {
  background-color: #695328;
  border: 0;
  border-radius: 10px;
  outline: none;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.login-section-three .inputchild0 {
  background-color: #ffffff1a !important;
  height: 50px;
  width: 85%;
  color: var(--color-1) !important;
}
.login-section-three .thirdpartchild0 ::placeholder {
  color: var(--color-1);
}
.login-section-three .thirdpartchild0 button {
  background-color: #daa520;
  border: 0;
  border-radius: 10px;
  color: #000000e3;
  outline: none;
  padding: 12px 13px;
}
.login-section-three .thirdpartchild0 .loadbtn {
  background-color: #daa520;
  border: 0;
  border-radius: 10px;
  color: #000000e3;
  outline: none;
  padding: 0px 24px 12px 24px;
}
.login-section-three .thirdpartchild0 .spinner-border {
  position: relative;
  top: 8px;
}
.login-section-three .thirdpartchild02 {
  background-color: var(--background-color-3);
  color: #ffffff80;
  border-radius: 15px;
  box-shadow: inset 2px 2px 5px 2px #cecece09;
  padding: 5px;
}
.login-section-three .thirdpartchild02BTN {
  background-color: var(--background-color-5);
  color: #ffffff;
  padding: 12px 20px;
  border-radius: 13px;
}
.login-section-three .thirdpartchild02BTN:hover {
  background-color: var(--background-color-4);
}
.login-section-three .thirdpartchild0 button:hover {
  background-color: var(--background-color-2);
}
.login-section-three .thirdpartchild h4 {
  color: var(--color-1);
}
.login-section-three .thirdpartchild02 .p-id {
  color: var(--color-1);
}
.login-section-three .thirdpartchild02BTN {
  color: var(--color-1);
}
.login-section-three .registerationradius {
  border-radius: 20px;
  background: var(--background-color-3);
}
.login-section-three .info-steps {
  font-size: 12px;
}
.login-section-three .info-button {
  border: none;
  padding: 5px 15px;
  background: var(--background-color-2);
  border-radius: 10px;
  font-size: 14px;
}
.login-section-three .text-size{
  font-size: 14px;
}


.login-section-three .info-button:hover {
  background: rgba(83, 121, 255, 1);
}
.login-section-four .fourthpartchild h5 {
  font-weight: bold;
  font-size: 30px;
  word-spacing: 5px;
  position: relative;
}
.login-section-four .fourthpartwhole0 {
  background: var(--background-color-3);
  border-radius: 20px;
  box-shadow: inset 2px 2px 5px 2px #cecece09;
  max-height: 700px;
  overflow-y: scroll;
}
.login-section-four .fourthbanner9 {
  border-bottom: 1px solid var(--background-color-5);
}
.login-section-four .fourthbanner10 span {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  color: white;
  background-color: #ffffff1a;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-section-four .fourthbannerbtn1:hover {
  background: var(--background-color-2);
  color: var(--background-color-1);
}
.login-section-four .fourthgreen {
  color: #7737ff;
}
.login-section-four .fourthbannerbtn2 {
  background-color: var(--background-color-4);
  color: var(--color-1);
  border: 0;
  outline: none;
  border-radius: 15px;
  letter-spacing: 1px;
  font-size: 18px;
  box-shadow: inset 0 0 4px 1px #80808026;
}
.login-section-four .fourthpartchild2,
.login-section-four .fourthpartchild3,
.login-section-four .fourthrounding {
  border-radius: 20px;
  background: var(--background-color-3);
  box-shadow: inset 0 0 4px 0 #80808026;
}
.login-section-four .bannercontent0 {
  font-size: 15px;
  color: var(--background-color-2);
  font-weight: bold;
}
.login-section-four .banneryear1,
.login-section-four .fourthiconchild10,
.login-section-four .bi-wallet {
  color: goldenrod;
}
.login-section-four .fourthbanner6,
.login-section-four .fourthbanner7 {
  border-bottom: 1px solid #80808026;
}
.login-section-four .fourthbanner30 {
  border-top: 1px solid #80808026;
}
/* .login-section-four .fourthbanner5 {
    color: grey;
} */
.login-section-four .fourthbannerbtn2:hover {
  background-color: var(--background-color-5);
}
.login-section-four .fourthpartchild h5 {
  color: var(--color-1);
}
.login-section-four .react-tooltip {
  background-color: rgb(199, 152, 31);
  color: var(--color-1);
}
.login-section-four .react-tooltip .platform-tt {
  z-index: 99;
  opacity: 9;
  padding: 5px;
  font-size: 12px;
  max-width: 250px;
  max-height: 100%;
  background-color: rgb(199, 152, 31);
  color: var(--color-1);
}
.dashboard-split-right-section .react-tooltip {
  background-color: rgb(199, 152, 31);
  color: var(--color-1);
}
.dashboard-split-right-section .react-tooltip .platform-tt {
  z-index: 99;
  opacity: 9;
  padding: 5px;
  font-size: 12px;
  max-width: 250px;
  max-height: 100%;
  background-color: rgb(199, 152, 31);
  color: var(--color-1);
}
.login-section-five .fifthbutton {
  padding: 12px 20px;
  background-color: var(--background-color-2);
}
.login-section-five .fifthbutton:hover {
  background-color: #406aff;
}
/* =========Login End =============== */
/* ========Registration Start ========== */
.register-section-one {
  background: var(--background-color-3);
}
.dark-theme .register-section-one {
  background: var(--background-color-1);
}
/* .register-section-one .navbar-brand {
    width: 90px;
    height: 90px;
} */
.register-section-one .btn33 {
  padding: 10px 20px;
  background-color: var(--background-color-5);
  color: var(--color-1);
  border: 0;
  outline: none;
  border-radius: 20px;
  width: max-content;
}
.register-section-one .btn33:hover,
.register-section-one .btn44:hover {
  background-color: var(--background-color-2);
}
.register-section-one .btn44 {
  background-color: var(--background-color-5);
  color: var(--color-1);
  border: 0;
  outline: none;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}
/* .d-ecosystem-registration .register-section-two{
    padding-top: 100px;
} */
.register-section-two .bannerinput {
  width: 60%;
  background-color: var(--background-color-5);
  color: var(--color-1);
}
.register-section-two .bannerinput {
  border-radius: 10px;
  outline: none;
  border: 0;
}
.register-section-two .banner11bttn1 {
  border: 0;
  outline: none;
  border-radius: 10px;
  background-color: var(--background-color-2);
}
.register-section-two .banner11bttn1:hover {
  background-color: rgba(83, 121, 255, 1);
}
.register-section-two .registerationradius {
  border-radius: 20px;
  background: var(--background-color-3);
}
.register-section-two .registerationbtn {
  border-radius: 20px;
}
.register-section-two .cardimages {
  width: 100%;
  height: 250px;
}
.register-section-two .lastarrowicons {
  top: 25%;
  left: 40%;
}
.register-section-two .cardiframe {
  display: none;
  width: 100%;
  height: 250px;
}
.register-section-two .card iframe {
  width: 100%;
  height: 100%;
}
.register-section-two .cardimages img {
  width: 100%;
  height: 100%;
}
.register-section-two .info-link {
  color:var(--background-color-2) !important;
  font-weight: 600;
}
.register-section-two .info-button{
  border: none;
  padding: 5px 15px;
  background: var(--background-color-2);
  border-radius: 10px;
  font-size: 14px;
}
.register-section-two .info-button:hover{
  background: rgba(83, 121, 255, 1);
}
.register-section-two .info-steps{
  font-size: 12px;
}

/* ========Registration End ========== */

/* ===========Error Page Start ============== */
/* Desktop */
@media (min-width: 992px) {
  .error-page {
    padding: 100px 100px;
    height: 100vh;
    background: black;
    color: white;
  }
  .error-page .text {
    text-transform: uppercase;
    font-size: 10rem !important;
    font-weight: 700;
    color: #f5f5f5;
    text-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191,
      1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191,
      1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191,
      1px 10px 1px #919191, 1px 18px 6px rgba(16, 16, 16, 0.4),
      1px 22px 10px rgba(16, 16, 16, 0.2), 1px 25px 35px rgba(16, 16, 16, 0.2),
      1px 30px 60px rgba(16, 16, 16, 0.4);
  }
}
/* Desktop */
/* Mobile */
@media (max-width: 992px) {
  .error-page {
    padding: 100px 10px;
    height: 100%;
    background: black;
    color: white;
  }
  .error-page .text {
    text-transform: uppercase;
    font-size: 5rem !important;
    font-weight: 700;
    color: #f5f5f5;
    text-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191,
      1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191,
      1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191,
      1px 10px 1px #919191, 1px 18px 6px rgba(16, 16, 16, 0.4),
      1px 22px 10px rgba(16, 16, 16, 0.2), 1px 25px 35px rgba(16, 16, 16, 0.2),
      1px 30px 60px rgba(16, 16, 16, 0.4);
  }
}
/* Mobile */
.error-page .goto-bt {
  background: var(--background-color-2) !important;
  color: white !important;
}
.error-page .goto-bt:hover {
  background: #406aff !important;
  color: white !important;
}
.error-page .error-img {
  background-repeat: no-repeat;
  width: 300px;
  height: 300px;
}
/* ===========Error Page End ============== */

@media (min-width: 1024px) and (max-width: 1030px) {
  .login-section-four .fourthbanner6,
  .login-section-four .fourthbanner7 {
    font-size: 13px;
  }
}

/* ==============calculator =============== */
.calculator .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--color-1);
  background: var(--background-color-2);
  padding: 10px 30px;
  font-weight: 600;
  border-radius: 10px;
}
.calculator .nav-pills .nav-link {
  color: var(--color-1);
  background: var(--background-color-5);
  padding: 10px 30px;
  font-weight: 600;
  border-radius: 10px;
}

@media (min-width: 992px) {
  .calculator .dashbanner1 h4 {
    font-size: 48px;
    font-weight: bolder;
    /* background: linear-gradient(180deg, #ffbe47, #ff4eaa); */
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    letter-spacing: 2px;
    background-image: linear-gradient(180deg, #fad961 0%, #f76b1c 100%);
  }
  .calculator .dashbanner1 h3 {
    font-size: 48px;
    font-weight: bolder;
  }
  .calculator .levelincome-table table thead th,
  .calculator .levelincome-table table tbody td {
    padding: 8px 15px;
    text-align: center;
    vertical-align: top;
  }
}

@media (max-width: 991px) {
  .calculator .dashbanner1 h4 {
    font-size: 38px;
    font-weight: bolder;
    /* background: linear-gradient(180deg, #ffbe47, #ff4eaa); */
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    letter-spacing: 2px;
    background-image: linear-gradient(180deg, #fad961 0%, #f76b1c 100%);
  }
  .calculator .dashbanner1 h3 {
    font-size: 38px;
    font-weight: bolder;
  }
  .calculator .levelincome-table table thead th,
  .calculator .levelincome-table table tbody td {
    padding: 5px 10px;
    text-align: center;
    vertical-align: top;
  }
}

.calculator .libtn {
  width: 50px;
  height: 50px;
  background: var(--background-color-5) !important;
  color: var(--color-1);
  border: 0;
  outline: none;
  border-radius: 15px;
}
.calculator .id-btn {
  width: 75px;
  height: 28px;
  background-color: var(--background-color-6);
  color: var(--color-1);
  border-radius: 15px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calculator .user-icon {
  font-size: 24px;
  padding: 4px;
  vertical-align: bottom;
}
/* .calculator .down-icon{
    margin-left: 30px;
} */
.calculator .levelincome-table {
  overflow: auto;
}
.calculator table {
}
.dark-theme .calculator .info-section {
  margin: 10px 0px;
  background: var(--background-color-6);
  padding: 10px;
  border-radius: 20px;
}
.calculator .info-section {
  margin: 10px 0px;
  background: var(--background-color-1);
  padding: 10px;
  border-radius: 20px;
}
.calculator .info-section p {
  font-size: 12px;
  font-weight: 600;
}
.calculator .info-btn {
  font-size: 12px;
}
.calculator .info-icon {
  font-size: 24px;
  padding: 4px;
}
.calculator .info-id-btn {
  width: 75px;
  height: 28px;
  background-color: var(--background-color-2);
  color: var(--color-1);
  border-radius: 15px;
  border: none;
  font-size: 12px;
  font-weight: 600;
}

.calculator .blink-soft {
  background-color: var(--background-color-2);
  color: var(--color-1);
  animation: infonote 2.5s infinite;
}
@keyframes infonote {
  0% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
}
.calculator .levelincome-table table thead th {
  color: var(--background-color-2);
}
.calculator .react-tooltip {
  background-color: var(--background-color-2);
}
.calculator .total-cost-info {
  width: 140px;
  height: 40px;
  font-size: 10px;
}


.calculator .getting-level {
  background-color: #daa520e9 !important;
  color: white;
}
.calculator .position-active{
  background: rgb(98,42,255);
  background: linear-gradient(90deg, rgba(98,42,255,1) 0%, rgba(208,58,148,1) 50%, rgba(207,146,31,1) 100%);
}
/* ==============calculator =============== */

/* Modal Processing */

/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal container */
.modal-overlay .modal-container {
  background-color: rgb(203, 203, 203);
  border: 1px solid #00e5ff21;
  color: var(--color-1);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}
.dark-theme .modal-overlay .modal-container {
  background-color: rgb(3,18,40);
  border: 1px solid #00e5ff21;
  color: var(--color-1);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}
.modal-overlay .modal-title {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.modal-overlay .modal-body {
  font-size: 14px;
  opacity: 0.8;
}




/* =======Career Modal =========== */
/* ====Desktop View ======= */
@media (min-width: 992px) {
  .career-modal .text-one{
    font-size: 14px;
  }
}
/* ====Desktop View End ======= */
/* ====Mobile view ========== */
@media (max-width: 991px) {
  .career-modal .text-one{
    font-size: 12px;
  }
}
/* ====Mobile view End========== */

.Careerform .career-btn{
  background-color: var(--background-color-4);
  color: var(--color-1);
  border: none;
  padding: 5px 15px 5px 10px;
  border-radius: 12px;
}
.Careerform .career-btn:hover{
  background-color: var(--background-color-5);
}
.career-modal .modal-dialog{
  --bs-modal-width:600px;
}
.career-modal .modal-content{
  background-color: var(--background-color-1) !important;
  color: var(--color-1);
}
.career-modal .modal-header .btn-close{
  filter: brightness(0.5) invert(0.5);
  margin: initial;
}
.career-modal .modal-title{
  color: var(--background-color-2);
  font-weight: 600;
}
.career-modal .text-y-class{
  color: var(--background-color-2);
}
.career-modal .form-control{
  appearance: none;
  background-clip: padding-box;
  background-color: transparent !important;
  border: 1px solid grey;
  border-radius: 0.375rem !important;
  box-shadow: none !important;
  color: var(--color-1) !important;
  width: 100%;
}
.career-modal .form-control::placeholder{
  color: var(--color-1);
  opacity: 0.7;
}
.career-modal .form-check-input{
  border-color: var(--color-1);
}
.career-modal .form-select{
  background-color: var(--background-color-1) !important;
  color: var(--color-1);
  border: 1px solid grey;
  border-radius: 0.375rem !important;
  box-shadow: none !important;
}
.career-modal .form-select::placeholder{
  color: var(--color-1);
  opacity: 0.7;
}
.career-modal .career-submit{
  background-color: var(--background-color-2);
  border: none;
  padding: 8px 30px;
}
.career-modal .career-submit:hover{
  background-color: #0d6efd;
}
.career-modal .error-text{
  color: red;
  font-size: 14px;
}
.career-modal .man-star{
  color: red;
  font-size: 14px;
}
/* Hide arrows in Firefox */
.career-modal input[type="number"] {
  -moz-appearance: textfield;
}

/* Hide arrows in Chrome, Safari, Edge, and Opera */
.career-modal input[type="number"]::-webkit-outer-spin-button,
.career-modal input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Careerform .fire-css{
  width: 28px;
  height: 25px;
}

/* =======Career Modal =========== */

/* ======Submit Modal ========== */
.submit-modal .modal-content{
  background-color: var(--background-color-1) !important;
  color: var(--color-1);
}
.submit-modal .modal-header .btn-close{
  filter: brightness(0.5) invert(0.5);
  margin: initial;
}
.submit-modal .modal-title{
  color: var(--background-color-2);
  font-weight: 600;
}
/* ======Submit Modal ========== */


/* ========switchchain ======== */
.switchchain .modal-content{
  background-color: var(--background-color-1);
  color: var(--color-1);
}
.switchchain .modal-header .btn-close{
  filter: brightness(0.5) invert(0.5);
}
.register-section-two .switch-btn {
  border: 0;
  outline: none;
  border-radius: 10px;
  background-color: var(--background-color-2);
}
.switchchain .buy-btn{
  background-color: var(--background-color-5);
    color: var(--color-1);
    padding: 5px 25px;
    border-radius: 10px;
    text-align: center;
    border: none;
    cursor: pointer;
}
.switchchain .buy-btn:hover{
  background-color: var(--background-color-2);
}
.switchchain .switch-coin{
  width: 23px;
  height: 23px;
  margin: 0px 4px 4px 0px;
}
.switchchain h6{
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
/* ========switchchain ======== */

